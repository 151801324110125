import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { Participant } from '../../core/api/portal.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.participant.errors && state.participant.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.participant.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Participant => state.participant.response || null;

export const hasSuccessSelector = (state: RootState): any | null => (state.participant.response ? true : false);

export const hasCheckedSelector = (state: RootState): boolean => state.participant.hasChecked;

export const hasUpdatedSelector = (state: RootState): boolean => state.participant.hasUpdated;

export const hasWithdrawnSelector = (state: RootState): boolean => state.participant.hasWithdrawn;

export const withdrawalLevelSelector = (state: RootState): string | undefined =>
  state.participant.response ? state.participant.response?.withdrawalLevel : undefined;

export const hasWithdrawnFullySelector = (state: RootState): boolean =>
  state.participant.response && state.participant.response?.withdrawalLevel === 'Complete';

export const updateEmailResponseSelector = (state: RootState): string | null => state.participant.updateEmailResponse;

export const updateEmailErrorSelector = (state: RootState): any | null => state.participant.updateEmailError;
