import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormCheckBox, VimeoPlayer, useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ReactMarkdown from 'react-markdown';
import { StepProps } from '../../../../core/types/common.types';

function VideoScene({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');

  const validationSchema = yup.object().shape({
    confirmVideo: yup.boolean().oneOf([true], t('forms.confirmVideo.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { confirmVideo: false, ...values },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'enrolment-video',
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={4}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {t('enrol.vid.title')}
        </Typography>
        <Typography variant="body1" component="span" align="center">
          <ReactMarkdown>{t('enrol.vid.text')}</ReactMarkdown>
        </Typography>
      </Box>
      <VimeoPlayer videoUrl={t('enrol.vid.videoUrl')} title={t('enrol.vid.videoTitle')} />
      <Box mb={4}>
        <Grid container>
          <Grid item xs={1} sm={2} md={0}></Grid>
          <Grid item xs={10} sm={8} md={12}>
            <FormCheckBox
              error={formik.errors.confirmVideo}
              name="confirmVideo"
              label={t('forms.confirmVideo.label')}
              onChange={formik.handleChange}
              touched={formik.touched.confirmVideo}
              value={formik.values.confirmVideo}
            />
          </Grid>
        </Grid>
      </Box>
      <ButtonRow buttonSize="medium" onBack={onStepBack} forwardColor="primary" showBack={currentStep > 0} />
    </form>
  );
}

export default VideoScene;
