import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { Loader, SectionBox, useMatomo } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import { Flags } from 'react-feature-flags';
import PortalLayout from '../../../../layouts/PortalLayout';
import { FEATURES } from '../../../../core/constants/features.constants';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { responseSelector, isBusySelector, updateParticipantPreferencesThunk } from '../../../../store/preferences';
import { responseSelector as profileSelector } from '../../../../store/participant';
import { Link } from 'react-router-dom';
import ROUTES from '../../../../core/constants/routes.constants';
import Paper from '@mui/material/Paper';
import ReactMarkdown from 'react-markdown';

function PreferencesScene() {
  const { t } = useTranslation('portal');
  const { trackEvent } = useMatomo();
  const preferences = useAppSelector(responseSelector);
  const profile = useAppSelector(profileSelector);
  const isBusy = useAppSelector(isBusySelector);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { ...preferences },
    onSubmit: (values: any) => {
      dispatch(updateParticipantPreferencesThunk(values));
      trackEvent({ category: 'click', action: 'update preferences' });
    },
  });

  const chosenWithdrawalOption = profile
    ? (
        (t('forms.withdrawalLevel.options', {
          returnObjects: true,
        }) as any[]) || []
      )
        .filter((item: any) => item.value === profile.withdrawalLevel)
        .pop()
    : undefined;

  return (
    <PortalLayout>
      <Box mb={3}>
        <Typography textAlign="center" variant="h5" component="h1" paragraph>
          {t('prefs.title')}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {t('prefs.text')}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <SectionBox>
          <Typography variant="h6" component="h2" paragraph>
            {t('prefs.ehr.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('prefs.ehr.line1')}
          </Typography>
          <Typography variant="body1">{t('prefs.ehr.line2')}</Typography>
          <FormControlLabel
            control={
              <Switch
                color="success"
                sx={{ m: 1 }}
                name="ehrOptin"
                checked={formik.values.ehrOptin}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.submitForm();
                }}
                inputProps={{ 'aria-label': t('prefs.ehr.option') as string }}
              />
            }
            label={t('prefs.ehr.option')}
          />
        </SectionBox>
        <SectionBox>
          <Typography variant="h6" component="h2" paragraph>
            {t('prefs.ancestry.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('prefs.ancestry.line1')}
          </Typography>
          <Typography variant="body1">{t('prefs.ancestry.line2')}</Typography>{' '}
          <FormControlLabel
            control={
              <Switch
                color="success"
                sx={{ m: 1 }}
                name="ancestryOptin"
                checked={formik.values.ancestryOptin}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.submitForm();
                }}
                inputProps={{
                  'aria-label': t('prefs.ancestry.option') as string,
                }}
              />
            }
            label={t('prefs.ancestry.option')}
          />
        </SectionBox>
        <SectionBox>
          <Typography variant="h6" component="h2" paragraph>
            {t('prefs.comms.title')}
          </Typography>
          <Typography variant="body1">{t('prefs.comms.line1')}</Typography>
          <FormControlLabel
            control={
              <Switch
                color="success"
                sx={{ m: 1 }}
                name="communicationOptin"
                checked={formik.values.communicationOptin}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.submitForm();
                }}
                inputProps={{
                  'aria-label': t('prefs.comms.option') as string,
                }}
              />
            }
            label={t('prefs.comms.option')}
          />
        </SectionBox>
      </form>
      <Flags authorizedFlags={[FEATURES.USER_WITHDRAWAL]}>
        <SectionBox color="error">
          <Typography variant="h6" component="h2" color="error" paragraph>
            {t('prefs.withdraw.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {t('prefs.withdraw.line1')}
          </Typography>
          {profile && chosenWithdrawalOption && (
            <Box mb={3}>
              <Paper variant="outlined">
                <Box p={3}>
                  <>
                    <Typography variant="body1" paragraph>
                      {t('prefs.withdraw.alreadyWithdrawn')}
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" component="span">
                      <ReactMarkdown>{chosenWithdrawalOption.labelShort}</ReactMarkdown>
                    </Typography>
                  </>
                </Box>
              </Paper>
            </Box>
          )}
          <Button
            component={Link}
            to={ROUTES.portalWithdraw}
            size="large"
            variant="contained"
            color="error"
            data-testid="withdrawal-btn"
          >
            {t('buttons.withdrawalStart')}
          </Button>
        </SectionBox>
      </Flags>
      <Loader isVisible={isBusy} label={`${t('loaders.updating')}`} />
    </PortalLayout>
  );
}

export default PreferencesScene;
