import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormCheckBox, SectionBox } from '@omnigenbiodata/ui';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import TermsConditions from '../../../../components/common/TermsConditions';
import ROUTES from '../../../../core/constants/routes.constants';
import PortalLayout from '../../../../layouts/PortalLayout';

function TermsScene() {
  const { t } = useTranslation('terms');
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    termsAccepted: yup.boolean().oneOf([true], t('forms.termsAccepted.msg.required', { ns: 'portal' }) as string),
  });

  const formik = useFormik({
    initialValues: { termsAccepted: false },
    validationSchema,
    onSubmit: () => {
      navigate(ROUTES.policiesPrivacy);
    },
  });

  return (
    <PortalLayout>
      <SectionBox>
        <TermsConditions />
        <form onSubmit={formik.handleSubmit}>
          <Box mb={4}>
            <FormCheckBox
              error={formik.errors.termsAccepted}
              name="termsAccepted"
              label={t('forms.termsAccepted.label', { ns: 'portal' })}
              onChange={formik.handleChange}
              touched={formik.touched.termsAccepted}
              value={formik.values.termsAccepted}
            />
          </Box>
          {formik.values.termsAccepted && (
            <Box mb={4}>
              <Typography textAlign="center" variant="body1" color="red" fontWeight="bold">
                {t('notice2')}
              </Typography>
            </Box>
          )}
          <ButtonRow
            buttonSize="medium"
            forwardColor="primary"
            forwardLabel={t('buttons.termsAccept', { ns: 'portal' }) as string}
            showBack={false}
          />
        </form>
      </SectionBox>
    </PortalLayout>
  );
}

export default TermsScene;
