import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { useAuth, Loader, DMUKLogo, SplashBox } from '@omnigenbiodata/ui';

import { AUTH_CHALLENGES } from '../../../../core/constants/aws.constants';
import ROUTES from '../../../../core/constants/routes.constants';
import AuthLayout from '../../../../layouts/AuthLayout';
import { LoginMethod } from '../../../../core/types/common.types';
import AuthLoginForm from '../../../../components/forms/AuthLoginForm';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { confirmSignupThunk, responseSelector, isBusySelector, hasErrorSelector } from '../../../../store/user';
import {
  getPracticeThunk,
  hasErrorSelector as hasPracticeErrorSelector,
  isBusySelector as isPracticeLoadingSelector,
} from '../../../../store/practices';
import { resultSelector } from '../../../../store/practices/selectors';
import PracticeBox from './components/PracticeBox';
import HelpBlock from '../../../../components/common/HelpBlock';
import { TRANSLATION_VARS } from '../../../../core/constants/content.constants';

function SignupScene() {
  const { t } = useTranslation('portal');
  const { signIn, challengeName, authChallengeParams, isBusy, hasError, user } = useAuth();
  const [submitCount, setSubmitCount] = useState(0);
  const [showHelp, toggleHelp] = useState(false);
  const dispatch = useAppDispatch();
  const uuid = useAppSelector(responseSelector);
  const practice = useAppSelector(resultSelector);
  const isUserBusy = useAppSelector(isBusySelector);
  const isPracticeBusy = useAppSelector(isPracticeLoadingSelector);
  const hasPracticeError = useAppSelector(hasPracticeErrorSelector);
  const hasUserError = useAppSelector(hasErrorSelector);
  const { odscode } = useParams();

  useEffect(() => {
    if (odscode) {
      dispatch(getPracticeThunk(odscode));
    }
  }, [dispatch, odscode]);

  useEffect(() => {
    if (uuid && !user && !isBusy && submitCount > 0) {
      signIn(uuid, undefined, {
        loginMethod: LoginMethod.Email,
      });
    }
  }, [hasError, signIn, user, uuid, dispatch, isBusy, submitCount]);

  const handleSubmit = (values: any) => {
    setSubmitCount(submitCount + 1);
    dispatch(
      confirmSignupThunk({
        loginMethod: LoginMethod.Email.toUpperCase(),
        loginParameter: values.email,
        odsCode: odscode,
      }),
    );
  };

  if (
    ((challengeName === AUTH_CHALLENGES.CUSTOM_CHALLENGE && authChallengeParams.email) || hasUserError) &&
    submitCount > 0
  ) {
    return <Navigate to={ROUTES.authMfa} />;
  }

  return (
    <AuthLayout>
      <SplashBox>
        <Box mb={2} mt={5} textAlign="center">
          <Link to={ROUTES.root}>
            <DMUKLogo size="xs" />
          </Link>
        </Box>
        <Box mb={4}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t('signup.title')}
          </Typography>
          <Typography paragraph variant="body1" align="center">
            {t('signup.text')}
          </Typography>
        </Box>
        <PracticeBox practice={practice} hasPracticeError={hasPracticeError} isBusy={isPracticeBusy} />
        {!hasPracticeError && (
          <Box mb={1}>
            <AuthLoginForm
              onsubmit={handleSubmit}
              buttonLabel={t('buttons.signUp') as string}
              helperText={t('signup.text2') as string}
            />
          </Box>
        )}
        <HelpBlock title={t('signup.help.title')} markdown={t('signup.help.text', TRANSLATION_VARS) as string} />
      </SplashBox>
      <Dialog open={showHelp} onClose={() => toggleHelp(false)}>
        <SplashBox>
          <Typography variant="body1">{t('signup.help.text', TRANSLATION_VARS)}</Typography>
        </SplashBox>
      </Dialog>
      <Loader isVisible={isBusy || isUserBusy} label={t('loaders.signUp') as string} />
    </AuthLayout>
  );
}

export default SignupScene;
