import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { theme, useAuth } from '@omnigenbiodata/ui';
import ReactMarkdown from 'react-markdown';
import { BsQuestionSquare } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function LockedOut() {
  const { t } = useTranslation('portal');
  const { signOut } = useAuth();

  return (
    <Dialog open>
      <DialogContent>
        <Box pt={5} pb={5}>
          <Grid container>
            <Grid item xs={0} sm={2} md={1}></Grid>
            <Grid item xs={12} sm={8} md={10}>
              <Box mb={4} pl={5} pr={5}>
                <Box textAlign="center">
                  <BsQuestionSquare fontSize={90} color={theme.palette.error.main} />
                </Box>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                  {t('alerts.QuestionsLocked.title')}
                </Typography>
                <Typography variant="body1" component="span" align="center">
                  <ReactMarkdown>{t('alerts.QuestionsLocked.text')}</ReactMarkdown>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  signOut();
                }}
              >
                {t('enrol.questions.buttons.end')}
              </Button>
            </Box>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default LockedOut;
