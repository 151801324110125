/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { DMUKLogo, PageWrapper } from '@omnigenbiodata/ui';

import ENV from '../../core/constants/environment.constants';
import Footer from '../../components/layout/Footer';
import ROUTES from '../../core/constants/routes.constants';
import { useStyles } from './component.styles';
import SideMenu from '../../components/layout/SideMenu';
import Hidden from '@mui/material/Hidden';

interface PortalLayoutProps {
  children: any;
}

function PortalLayout({ children }: PortalLayoutProps) {
  const classes = useStyles();
  const [isMenuOpenFlag, setIsMenuOpenFlag] = useState(false);

  const handleToggleMenu = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsMenuOpenFlag(open);
  };

  return (
    <PageWrapper isShaded>
      <Box mb={2}>
        <Toolbar disableGutters>
          <div style={{ flexGrow: 1 }}>
            <Hidden only="xs">
              <Link to={ROUTES.portal}>
                <DMUKLogo size="sm" />
              </Link>
            </Hidden>
            <Hidden smUp>
              <Link to={ROUTES.portal}>
                <DMUKLogo size="xs" />
              </Link>
            </Hidden>
          </div>
          <IconButton onClick={handleToggleMenu(true)} edge="end" css={classes.appBarHamburger} data-testid="menu-btn">
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Box>
      <SideMenu isOpen={isMenuOpenFlag} onToggle={handleToggleMenu} />
      <Fade in timeout={500}>
        <div css={classes.content}>{children}</div>
      </Fade>
      <Footer version={ENV.VERSION} />
    </PageWrapper>
  );
}

export default PortalLayout;
