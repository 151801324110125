import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, SectionBox, FormRadioBlockGroup } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { MultiStepProps } from '../../../../../../components/library/MultiStepper';
// import ReactMarkdown from "react-markdown";

export const WithdrawalLevel = ({ formik, stepBack }: MultiStepProps) => {
  const { t } = useTranslation('portal');
  return (
    <SectionBox>
      <Box mb={3}>
        <Typography textAlign="center" variant="h5" component="h1" color="error" gutterBottom>
          {t('withdrawal.title')}
        </Typography>
        <Typography textAlign="center" variant="h6" color="error" paragraph>
          {t('withdrawal.options.title')}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {t('withdrawal.options.text')}
        </Typography>
        <Typography textAlign="center" variant="body1" component="span">
          <ReactMarkdown>{t('withdrawal.options.text2')}</ReactMarkdown>
        </Typography>
      </Box>
      <FormRadioBlockGroup
        label={`${t('forms.withdrawalLevel.label')}`}
        error={formik.errors.withdrawalLevel}
        name={'withdrawalLevel'}
        onChange={formik.handleChange}
        touched={formik.touched.withdrawalLevel}
        value={formik.values.withdrawalLevel}
        color="error"
        showOr={false}
        hideLabel
        options={
          t('forms.withdrawalLevel.options', {
            returnObjects: true,
          }) as any
        }
      />
      <ButtonRow
        onBack={stepBack}
        forwardLabel={`${t('buttons.forward')}`}
        backLabel={`${t('buttons.back')}`}
        justifyContent="center"
        buttonSize="medium"
        forwardColor="error"
      />
    </SectionBox>
  );
};

export default WithdrawalLevel;
