/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { RiLockFill } from 'react-icons/ri';
import { FormInput, useTrackedFormik } from '@omnigenbiodata/ui';
import Button from '@mui/material/Button';

export interface AuthVerifyFormProps {
  onSubmit?: (values: any) => void;
}

function AuthVerifyForm({ onSubmit }: AuthVerifyFormProps) {
  const validationSchema = yup.object().shape({
    code: yup.string().required('A valid verification code is required'),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { code: '' },
      validationSchema,
      onSubmit: (values: any) => {
        if (onSubmit) {
          onSubmit(values);
        }
      },
    },
    'verify-email',
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={5}>
        <FormInput
          error={formik.errors.code}
          name="code"
          label={'Verification code'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.code}
          value={formik.values.code}
          startAdornment={<RiLockFill fontSize="large" />}
        />
      </Box>
      <Box>
        <Button data-testid="verify-button" size="large" fullWidth variant="contained" color="primary" type="submit">
          Verify
        </Button>
      </Box>
    </form>
  );
}

export default AuthVerifyForm;
