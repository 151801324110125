import Box from '@mui/material/Box';
import { FormCheckBox, useTrackedFormik } from '@omnigenbiodata/ui';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StepProps } from '../../../../core/types/common.types';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function Consent5Step({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');

  const validationSchema = yup.object().shape({
    consent5: yup
      .boolean()
      .required(t('forms.consent5.msg.required') as string)
      .oneOf([true], t('forms.consent5.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { consent5: false, ...values },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'consent5',
  );

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.consent5.title')}
      text={t('enrol.consent5.text') as string}
      onBack={onStepBack}
      showBack={currentStep > 0}
    >
      <Box mb={4} px={{ xs: 3, sm: 0 }}>
        <FormCheckBox
          error={formik.errors.consent5}
          name="consent5"
          label={t('forms.consent5.label')}
          onChange={formik.handleChange}
          touched={formik.touched.consent5}
          value={formik.values.consent5}
        />
      </Box>
    </EnrolmentStepContainer>
  );
}

export default Consent5Step;
