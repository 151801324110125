import { css } from '@emotion/react';

export const useStyles = () => {
  return {
    container: css`
      flex-grow: 0;
      margin-top: 40px;
      text-align: center;
    `,
  };
};
