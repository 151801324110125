import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.addressLookup.errors && state.addressLookup.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.addressLookup.status === StoreStatus.BUSY;

export const addressesSelector = (state: RootState): any | null => state.addressLookup.addresses || null;

export const addressDetailsSelector = (state: RootState): any | null => state.addressLookup.addressDetails || null;
