/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SectionBox } from '@omnigenbiodata/ui';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';

export interface HelpBoxProps {
  title: string;
  markdown: string;
}
export const HelpBox = ({ title, markdown }: HelpBoxProps) => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const handleOpenHelp = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  return (
    <>
      <Box mb={1}>
        <Button size="large" fullWidth variant="text" color="primary" type="button" onClick={handleOpenHelp}>
          {title}
        </Button>
      </Box>
      {isHelpOpen && (
        <SectionBox>
          <Typography variant="body1" component="span">
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </Typography>
        </SectionBox>
      )}
    </>
  );
};

export default HelpBox;
