/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ButtonRow, theme } from '@omnigenbiodata/ui';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import ReactMarkdown from 'react-markdown';

export interface ResultBoxProps {
  title: string;
  text: string;
  button?: string;
  onClick?: () => void;
  severity?: 'success' | 'error';
}
function ResultBox({ title, text, button, onClick, severity = 'success' }: ResultBoxProps) {
  return (
    <>
      <Grid container>
        <Grid item xs={1} sm={2} md={1}></Grid>
        <Grid item xs={10} sm={8} md={10}>
          <Box mb={4} pl={5} pr={5}>
            <Box textAlign="center">
              {severity === 'success' && <AiOutlineCheckCircle fontSize={90} color={theme.palette.success.main} />}
              {severity === 'error' && <AiOutlineCloseCircle fontSize={90} color={theme.palette.error.main} />}
            </Box>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body1" component="span" align="center">
              <ReactMarkdown>{text}</ReactMarkdown>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {button && (
        <ButtonRow
          buttonSize="medium"
          showBack={false}
          justifyContent="center"
          forwardColor="primary"
          forwardLabel={button}
          onForward={() => {
            if (onClick) {
              onClick();
            }
          }}
        />
      )}
    </>
  );
}

export default ResultBox;
