import { createSlice } from '@reduxjs/toolkit';
import { PracticesState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { getPracticeThunk, searchPracticeThunk } from './thunks';

// Initial State
const initialState: PracticesState = {
  errors: null,
  status: StoreStatus.IDLE,
  values: null,
  list: null,
  result: null,
};

// Slice
export const practicesSlice = createSlice({
  name: 'practices',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchPracticeThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(searchPracticeThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.list = action.payload;
      state.errors = null;
    });
    builder.addCase(searchPracticeThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.list = null;
    });
    builder.addCase(getPracticeThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(getPracticeThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.result = action.payload;
      state.errors = null;
    });
    builder.addCase(getPracticeThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.result = null;
    });
  },
});

// Action creators
export const { reset } = practicesSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default practicesSlice.reducer;
