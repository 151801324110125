/** @jsxImportSource @emotion/react */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import PrivacyNotice from '../../common/PrivacyNotice';
import TermsConditions from '../../common/TermsConditions';
import { useStyles } from './component.styles';

interface FooterProps {
  version?: string;
}

function Footer({ version }: FooterProps) {
  const classes = useStyles();
  const [isTermsOpen, setTermsOpen] = useState<boolean>(false);
  const [isPrivacyOpen, setPrivacyOpen] = useState<boolean>(false);

  const handleTermsClose = () => {
    setTermsOpen(false);
  };
  const handleTermsOpen = () => {
    setTermsOpen(true);
  };

  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
  };
  const handlePrivacyOpen = () => {
    setPrivacyOpen(true);
  };

  return (
    <>
      <div css={classes.container}>
        <Typography variant="body2" component="p" align="center" paragraph>
          Copyright &copy; Omnigen Biodata {new Date().getFullYear()}
          {version && <> | Version: {version}</>}
        </Typography>
        <Typography variant="body2" component="span" align="center">
          <Button
            aria-expanded={isTermsOpen}
            data-testid="footer-terms"
            variant="text"
            tabIndex={0}
            onClick={handleTermsOpen}
          >
            Terms &amp; Conditions
          </Button>{' '}
          |{' '}
          <Button aria-expanded={isPrivacyOpen} data-testid="footer-privacy" variant="text" onClick={handlePrivacyOpen}>
            Privacy
          </Button>{' '}
        </Typography>
      </div>
      <Dialog fullWidth maxWidth="md" open={isTermsOpen} onClose={handleTermsClose}>
        <DialogContent>
          <TermsConditions />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTermsClose} data-testid="terms-close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="md" open={isPrivacyOpen} onClose={handlePrivacyClose}>
        <DialogContent>
          <PrivacyNotice />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrivacyClose} data-testid="privacy-close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Footer;
