import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Map } from 'immutable';
import Question from '../Question';
import * as yup from 'yup';
import { useFormik } from 'formik';
import HelpDrawer from '../HelpDrawer';
import Button from '@mui/material/Button';
import { ButtonRow, useMatomo } from '@omnigenbiodata/ui';
import { useState } from 'react';

export interface QuestionsFormProps {
  onStepBack: () => void;
  onSubmit: (success: boolean) => void;
  questions: any[];
  pis: any;
  disabled: boolean;
}
function QuestionsForm({ questions, onSubmit, onStepBack, pis, disabled }: QuestionsFormProps) {
  const [helpDrawerState, setHelpDrawerState] = useState({
    q1: false,
    q2: false,
    q3: false,
  });

  const validationSchema = yup.object().shape({
    q1: yup.string().required('Required'),
    q2: yup.string().required('Required'),
    q3: yup.string().required('Required'),
  });
  const { trackEvent } = useMatomo();
  const formik = useFormik({
    validationSchema,
    initialValues: {
      q1: '',
      q2: '',
      q3: '',
    },
    onSubmit: (values: any) => {
      const userAnswers = Map(values);
      const correctAnswers = Map(
        questions.reduce((existing: any, q: any, i: number) => {
          return {
            ...existing,
            ...{
              [`q${i + 1}`]: q.answers
                .map((a: any, aIndex: number) => {
                  return { ...a, index: aIndex.toString() };
                })
                .filter((a: any) => a.isCorrect)
                .map((a: any) => a.index)
                .pop(),
            },
          };
        }, {}),
      );
      const isCorrect = userAnswers.equals(correctAnswers);
      trackEvent({
        category: 'enrolment-questions',
        action: `submit ${isCorrect ? 'correct' : 'incorrect'} answers`,
      });
      onSubmit(isCorrect);
    },
  });

  const toggleHelpDrawer = (question: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setHelpDrawerState({ ...helpDrawerState, [question]: open });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={4}>
        <Grid container>
          <Grid item xs={12}>
            {questions.map(({ question, answers, references }: any, index: number) => (
              <Box key={`question${index}`} mb={4}>
                <Paper variant="outlined">
                  <Box p={5}>
                    <Question
                      disabled={disabled}
                      onChange={formik.handleChange}
                      value={formik.values[`q${index + 1}` as keyof typeof formik.values]}
                      error={formik.errors[`q${index + 1}` as keyof typeof formik.errors]}
                      touched={formik.touched[`q${index + 1}` as keyof typeof formik.touched]}
                      questionIndex={index}
                      question={question}
                      answers={answers}
                      correctAnswer={answers.filter((ans: any) => ans.isCorrect)}
                    />

                    <Button variant="text" onClick={toggleHelpDrawer(`q${index + 1}`, true)}>
                      Need some help?
                    </Button>
                    <HelpDrawer
                      id={`q${index + 1}`}
                      isOpen={helpDrawerState[`q${index + 1}` as keyof typeof helpDrawerState]}
                      onToggle={toggleHelpDrawer}
                      pis={pis}
                      references={references}
                    />
                  </Box>
                </Paper>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
      <ButtonRow buttonSize="medium" onBack={onStepBack} forwardColor="primary" />
    </form>
  );
}

export default QuestionsForm;
