/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ScrollablePanel } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { TRANSLATION_VARS } from '../../../core/constants/content.constants';

function ParticipantInformation() {
  const { t } = useTranslation('pis');

  return (
    <Paper variant="outlined">
      <Box p={3}>
        <Box mb={3}>
          <Typography textAlign="center" variant="h5" component="h1" paragraph>
            {t('title')}
          </Typography>
          <Typography textAlign="center" variant="h6" component="h2">
            {t('subtitle')}
          </Typography>
        </Box>
        <ScrollablePanel>
          <Typography variant="body1" component="span">
            <ReactMarkdown linkTarget="_blank">{t('intro')}</ReactMarkdown>
          </Typography>
          {Object.values(
            t('sections', {
              returnObjects: true,
              ...TRANSLATION_VARS,
            }),
          ).map((section: any, index: number) => (
            <span key={`section${index}`}>
              <Typography variant="h6" component="h2" align="left" gutterBottom>
                {index + 1}. {section.title}
              </Typography>
              <Typography component="span" variant="body1" align="left">
                <ReactMarkdown linkTarget="_blank">{section.text}</ReactMarkdown>
              </Typography>
            </span>
          ))}
          <Typography variant="body2" align="left" fontWeight="bold">
            {t('version')} - {t('date')}
          </Typography>
        </ScrollablePanel>
      </Box>
    </Paper>
  );
}

export default ParticipantInformation;
