import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Message, Loader } from '@omnigenbiodata/ui';
import { StoreStatus } from '../../../../../../core/types/common.types';
import { ReactNode } from 'react';

interface EhrTabProps {
  data: {
    errors: any;
    status: StoreStatus;
    response: any;
  };
  showCaveat?: boolean;
  showCount?: boolean;
  icon: ReactNode;
  type: string;
  title: string;
  children: ReactNode;
}

const EhrTab = ({ data, showCaveat, showCount, type, title, icon, children }: EhrTabProps) => {
  const { t } = useTranslation('portal');

  return (
    <div>
      <Typography mb={3} variant="h5" component="h3" paragraph display="flex" alignItems="center">
        {icon} <Box ml={1}>{title}</Box>
      </Typography>
      {showCaveat && (
        <Message severity="info">
          <ReactMarkdown>{t(`${type}.caveat`)}</ReactMarkdown>
        </Message>
      )}
      {data.response && (
        <>
          {showCount && (
            <Typography variant="body1" component="p" mb={3}>
              {data.response.length === 1
                ? t(`${type}.single`, { total: data.response.length })
                : t(`${type}.multiple`, {
                    total: data.response.length,
                  })}
            </Typography>
          )}
          {children}
        </>
      )}
      <Loader isVisible={data.status === StoreStatus.BUSY} label={t('loaders.waiting') as string} />
    </div>
  );
};

export default EhrTab;
