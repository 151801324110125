/** @jsxImportSource @emotion/react */
import { useFormik } from 'formik';
import { ReactNode, useState } from 'react';
import { FormStepper } from '@omnigenbiodata/ui';
import Box from '@mui/material/Box';

export interface MultiStep {
  stageIndex: number; // maps to index of stages passed
  component: (props: MultiStepProps) => ReactNode; // component t to render when step is active
  validationSchema?: any; // Formik validation schema
  submit?: boolean; // should this step submit or step forward
  stepFn?: (values: any) => void;
}

export interface MultiStepperProps {
  stages?: string[];
  steps: MultiStep[];
  initialValues: any;
  onSubmit?: (values: any) => void;
}

export interface MultiStepProps {
  formik: any;
  stepBack: () => undefined;
}

function MultiStepper({ steps, initialValues, stages, onSubmit }: MultiStepperProps) {
  const [stepIndex, setStepIndex] = useState(0);
  const currentStep = steps[stepIndex];
  const StepComponent = currentStep.component as any;
  const validationSchema = currentStep.validationSchema as any;
  const activeStage = currentStep.stageIndex || 0;

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: validationSchema || undefined,
    onSubmit: (values: any) => {
      if (currentStep.stepFn) {
        currentStep.stepFn(values);
      }
      if (currentStep.submit) {
        if (onSubmit) {
          onSubmit(values);
        }
      } else {
        setStepIndex(stepIndex + 1);
      }
    },
  });

  const handleStepBack = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  return (
    <>
      {stages && (
        <Box mb={5} sx={{ overflowY: { xs: 'auto' } }}>
          <FormStepper steps={stages} activeStep={activeStage} />
        </Box>
      )}
      <form onSubmit={formik.handleSubmit}>
        <StepComponent formik={formik} stepBack={handleStepBack} />
      </form>
    </>
  );
}

export default MultiStepper;
