import FAQs from '../../../../components/common/FAQs';
import PortalLayout from '../../../../layouts/PortalLayout';

function PISScene() {
  return (
    <PortalLayout>
      <FAQs />
    </PortalLayout>
  );
}

export default PISScene;
