import { useState } from 'react';
import Box from '@mui/material/Box';
import { FormStepper, Loader, ScrollToTop, SectionBox, useMatomo } from '@omnigenbiodata/ui';
import { ENROLMENT_STEPS } from '../../core/constants/content.constants';
import EnrolmentLayout from '../../layouts/EnrolmentLayout';
import EligibilityStep from './components/EligibilityStep';
import VideoStep from './components/VideoStep';
import PisStep from './components/PisStep';
import QuestionsStep from './components/QuestionsStep';
import ConsentStep from './components/ConsentStep';
import Consent1Step from './components/Consent1Step';
import Consent2Step from './components/Consent2Step';
import Consent3Step from './components/Consent3Step';
import Consent4Step from './components/Consent4Step';
import Consent5Step from './components/Consent5Step';
import Consent6Step from './components/Consent6Step';
import Consent7Step from './components/Consent7Step';
import Consent8Step from './components/Consent8Step';
import Consent9Step from './components/Consent9Step';
import Details1Step from './components/Details1Step';
import Details2Step from './components/Details2Step';
import ReviewStep from './components/ReviewStep';
import { StepProps } from '../../core/types/common.types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  createParticipantThunk,
  hasSuccessSelector,
  hasErrorSelector,
  isBusySelector,
  firstErrorSelector,
} from '../../store/enrolment';
import { CreateParticipantInput } from '../../core/api/portal.types';
import SuccessStep from './components/SuccessStep';
import { hasCheckedSelector } from '../../store/participant';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../core/constants/routes.constants';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const StepComponent = (props: StepProps) => {
  switch (props.currentStep) {
    case 0:
      return <EligibilityStep {...props} />;
    case 1:
      return <VideoStep {...props} />;
    case 2:
      return <PisStep {...props} />;
    case 3:
      return <QuestionsStep {...props} />;
    case 4:
      return <ConsentStep {...props} />;
    case 5:
      return <Consent1Step {...props} />;
    case 6:
      return <Consent2Step {...props} />;
    case 7:
      return <Consent3Step {...props} />;
    case 8:
      return <Consent4Step {...props} />;
    case 9:
      return <Consent5Step {...props} />;
    case 10:
      return <Consent6Step {...props} />;
    case 11:
      return <Consent7Step {...props} />;
    case 12:
      return <Consent8Step {...props} />;
    case 13:
      return <Consent9Step {...props} />;
    case 14:
      return <Details1Step {...props} />;
    case 15:
      return <Details2Step {...props} />;
    case 16:
      return <ReviewStep {...props} />;
    default:
      return <></>;
  }
};

function EnrolmentScene() {
  const { t } = useTranslation('portal');
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [values, setValues] = useState<object>({});
  const dispatch = useAppDispatch();
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const hasError = useAppSelector(hasErrorSelector);
  const firstErrorType = useAppSelector(firstErrorSelector);
  const hasCheckedProfile = useAppSelector(hasCheckedSelector);
  const isBusy = useAppSelector(isBusySelector);
  const { trackPageView } = useMatomo();

  // Map individual steps to a step on the progress bar
  const PROGRESS_STEPS = [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2];
  const SUBPAGES = [
    'eligibility',
    'video',
    'pis',
    'questions',
    'consent',
    'consent1',
    'consent2',
    'consent3',
    'consent4',
    'consent5',
    'consent6',
    'consent7',
    'consent8',
    'consent9',
    'details1',
    'details2',
    'review',
  ];

  const handleStepForward = (newValues: object) => {
    setHasSubmitted(false);
    setStep(currentStep + 1);
    setValues({ ...values, ...newValues });
  };

  const handleStepBack = () => {
    setHasSubmitted(false);
    setStep(currentStep - 1 >= 0 ? currentStep - 1 : currentStep);
  };

  const handleStepTo = (newStep: number) => {
    setHasSubmitted(false);
    setStep(newStep);
  };

  const setStep = (newStep: number) => {
    setCurrentStep(newStep);
    trackPageView({
      href: `${window.location.href}${SUBPAGES[newStep]}`,
    });
  };

  const handleSubmit = () => {
    setHasSubmitted(true);
    dispatch(createParticipantThunk(values as CreateParticipantInput));
  };

  if (!hasCheckedProfile) {
    return <Navigate to={ROUTES.root} />;
  }

  return (
    <EnrolmentLayout>
      <SectionBox>
        <ScrollToTop identifier={currentStep.toString()} />
        <Box mb={4} sx={{ overflowY: { xs: 'auto' } }}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={!hasSuccess ? PROGRESS_STEPS[currentStep] : 3} />
        </Box>
        {!hasSuccess && (
          <StepComponent
            currentStep={currentStep}
            onStepForward={handleStepForward}
            onStepBack={handleStepBack}
            onStepTo={handleStepTo}
            onSubmit={handleSubmit}
            values={values}
            isBusy={isBusy}
            hasError={hasSubmitted && hasError && !isBusy}
            errorType={firstErrorType}
          />
        )}
        {hasSuccess && <SuccessStep />}
      </SectionBox>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Box textAlign="center">
            <img src="/images/OmnigenFullColour.png" alt="Omnigen" width={130} />
          </Box>
        </Grid>
      </Grid>
      <Loader isVisible={isBusy} label={t('loaders.busy') as string} />
    </EnrolmentLayout>
  );
}

export default EnrolmentScene;
