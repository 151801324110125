export const confirmSignUpMutation = `
mutation confirmSignUpMutation($loginMethod: String!, $loginParameter: String!, $odsCode: String)  {
  confirmSignUp(loginMethod: $loginMethod, loginParameter:$loginParameter, odsCode:$odsCode)
}`;

export const createParticipantMutation = `
mutation createParticipant($input: NewParticipantInput!) {
    createParticipant(input: $input) {
        NHSNumber
        PISConfirm
        VideoConfirm
        address1
        address2
        town
        consent1
        consent2
        consent3
        consent4
        consent5
        consent6
        consent7
        consent8
        consent9
        consentFormDate
        consentFormVersion
        createdTs
        dob
        firstName
        genderIdentity
        withdrawalTs
        withdrawalReason
        withdrawalLevel
        updatedTs
        termsAccepted
        surname
        sexAtBirth
        privacyAccepted
        postcode
        odsCode
        mobile
        landline
        genderIdentityOther
        termsVersion
        privacyVersion
        sampleBarcode
        orderStatus
    },
}
`;

export const updateParticipantMutation = `
mutation updateParticipant($input: UpdateParticipantInput!) {
    updateParticipant(input: $input) {
        NHSNumber
        PISConfirm
        VideoConfirm
        address1
        address2
        town
        consent1
        consent2
        consent3
        consent4
        consent5
        consent6
        consent7
        consent8
        consent9
        consentFormDate
        consentFormVersion
        createdTs
        dob
        firstName
        genderIdentity
        withdrawalTs
        withdrawalReason
        withdrawalLevel
        updatedTs
        termsAccepted
        surname
        sexAtBirth
        privacyAccepted
        postcode
        odsCode
        mobile
        landline
        genderIdentityOther
        termsVersion
        privacyVersion
    },
}
`;

export const updateParticipantPreferencesMutation = `
mutation updateParticipantPreferences($input: UpdatedPreferencesInput!) {
    updateParticipantPreferences(input: $input) {
       ehrOptin
       ancestryOptin
       communicationOptin
    },
}
`;

export const acceptLegalNoticesMutation = `
mutation acceptLegalNotices($input: LegalNoticesInput!) {
    acceptLegalNotices(input: $input) {
        NHSNumber
        PISConfirm
        VideoConfirm
        address1
        createdTs
        dob
        firstName
        genderIdentity
        genderIdentityOther
        landline
        mobile
        odsCode
        postcode
        sexAtBirth
        surname
        updatedTs
        withdrawalLevel
        termsVersion
        privacyVersion
    },
}
`;

export const withdrawParticipantMutation = `
mutation withdrawParticipant($withdrawalLevel: String!, $withdrawalReason: String!) {
    withdrawParticipant(withdrawalLevel: $withdrawalLevel, withdrawalReason: $withdrawalReason) {
       withdrawalLevel
       withdrawalReason
    },
}
`;

export const updateParticipantEmailMutation = `
mutation updateParticipantEmail($code: String!) {
    updateParticipantEmail(code: $code)
}
`;
