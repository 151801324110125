import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import {
  FormInput,
  FormDob,
  FormButtonGroup,
  FormSelect,
  useAuth,
  SelectOption,
  useTrackedFormik,
} from '@omnigenbiodata/ui';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import differenceInYears from 'date-fns/differenceInYears';
import { StepProps } from '../../../../core/types/common.types';
import { GENDER_IDENTITY_OTHER } from '../../../../core/constants/content.constants';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function Details1Step({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');
  const { user } = useAuth();

  const validationSchema = yup.object().shape({
    NHSNumber: yup
      .string()
      .matches(/^([0-9]{10})$/, t('forms.NHSNumber.msg.minLength') as string)
      .required(t('forms.NHSNumber.msg.required') as string),
    confirmNHSNumber: yup
      .string()
      .oneOf([yup.ref('NHSNumber'), null], t('forms.confirmNHSNumber.msg.required') as string)
      .required(t('forms.confirmNHSNumber.msg.required') as string),
    dob: yup
      .string()
      .test('dob', t('forms.dob.msg.minAge') as string, function (value) {
        return differenceInYears(new Date(), new Date(value || '')) >= 18;
      })
      .required(t('forms.dob.msg.required') as string),
    sexAtBirth: yup.string().required(t('forms.sexAtBirth.msg.required') as string),
    genderIdentityOther: yup.string().when('genderIdentity', (genderIdentity) => {
      if (genderIdentity === GENDER_IDENTITY_OTHER) {
        return yup.string().required(t('forms.genderIdentityOther.msg.required') as string);
      }
      return yup.string();
    }),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        NHSNumber: '',
        confirmNHSNumber: '',
        dob: ((user as any)?.attributes?.birthdate as string) || '',
        sexAtBirth: '',
        genderIdentity: '',
        genderIdentityOther: '',
        ...values,
      },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward({
          ...values,
          genderIdentityOther: values.genderIdentity === GENDER_IDENTITY_OTHER ? values.genderIdentityOther : '',
        });
      },
    },
    'enrolment-details1',
  );

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.details1.title')}
      text={t('enrol.details1.text') as string}
      onBack={onStepBack}
      showBack={currentStep > 0}
    >
      <Box mb={4}>
        <FormInput
          error={formik.errors.NHSNumber}
          name="NHSNumber"
          label={t('forms.NHSNumber.label')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            formik.setFieldValue('NHSNumber', e.target.value.replace(/([a-zA-Z -])/g, ''));
          }}
          onBlur={formik.handleBlur}
          touched={formik.touched.NHSNumber}
          value={formik.values.NHSNumber}
        />
        <FormInput
          error={formik.errors.confirmNHSNumber}
          name="confirmNHSNumber"
          label={t('forms.confirmNHSNumber.label')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            formik.setFieldValue('confirmNHSNumber', e.target.value.replace(/([a-zA-Z -])/g, ''));
          }}
          onBlur={formik.handleBlur}
          touched={formik.touched.confirmNHSNumber}
          value={formik.values.confirmNHSNumber}
        />
        <FormDob
          error={formik.errors.dob}
          name="dob"
          label={t('forms.dob.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          setValue={formik.setFieldValue}
          touched={formik.touched.dob}
          value={formik.values.dob}
          minAge={18}
        />
        <FormButtonGroup
          error={formik.errors.sexAtBirth}
          name="sexAtBirth"
          label={t('forms.sexAtBirth.label')}
          setValue={formik.setFieldValue}
          touched={formik.touched.sexAtBirth}
          value={formik.values.sexAtBirth}
          options={
            t('forms.sexAtBirth.options', {
              returnObjects: true,
            }) as [SelectOption]
          }
        />
        <FormSelect
          placeholder=""
          error={formik.errors.genderIdentity}
          name="genderIdentity"
          label={t('forms.genderIdentity.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.genderIdentity}
          value={formik.values.genderIdentity}
          options={
            t('forms.genderIdentity.options', {
              returnObjects: true,
            }) as [SelectOption]
          }
        />
        {formik.values.genderIdentity === GENDER_IDENTITY_OTHER && (
          <FormInput
            error={formik.errors.genderIdentityOther}
            name="genderIdentityOther"
            label={t('forms.genderIdentityOther.label')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.genderIdentityOther}
            value={formik.values.genderIdentityOther}
          />
        )}
      </Box>
    </EnrolmentStepContainer>
  );
}

export default Details1Step;
