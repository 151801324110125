import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SectionBox, theme } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../../core/constants/routes.constants';
import { useAppSelector } from '../../../../../../store';
import { withdrawalLevelSelector } from '../../../../../../store/participant';
import { STUDY_EMAIL, STUDY_TEL } from '../../../../../../core/constants/content.constants';
import Button from '@mui/material/Button';
import { BsPrinter } from 'react-icons/bs';

function SuccessStep() {
  const { t } = useTranslation('portal');
  const navigate = useNavigate();
  const withdrawalLevel = useAppSelector(withdrawalLevelSelector);

  const chosenOption = (
    (t('forms.withdrawalLevel.options', {
      returnObjects: true,
    }) as any[]) || []
  )
    .filter((item: any) => item.value === withdrawalLevel)
    .pop();

  const handlePrint = () => {
    window.print();
  };

  return (
    <SectionBox>
      <Grid container>
        <Grid item xs={1} sm={2} md={1}></Grid>
        <Grid item xs={10} sm={8} md={10}>
          <Box mb={4} pl={5} pr={5}>
            <Box textAlign="center">
              <AiOutlineCheckCircle fontSize={90} color={theme.palette.success.main} />
            </Box>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              {t('withdrawal.success.title')}
            </Typography>
            <Typography variant="body1" component="span" align="center" paragraph>
              <ReactMarkdown>{t('withdrawal.success.text')}</ReactMarkdown>
            </Typography>

            {chosenOption && (
              <Box mb={3}>
                <Typography textAlign="center" variant="body1" component="span">
                  <strong>
                    <ReactMarkdown>{chosenOption.labelShort}</ReactMarkdown>
                  </strong>
                </Typography>
              </Box>
            )}

            {withdrawalLevel === 'Complete' && (
              <Typography textAlign="center" variant="body1" paragraph>
                {t('withdrawal.success.complete')}
              </Typography>
            )}

            <Typography textAlign="center" variant="body1">
              {t('withdrawal.success.text2', {
                telephone: STUDY_TEL,
                email: STUDY_EMAIL,
              })}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box textAlign="center" mb={3}>
        <Button
          onClick={() => {
            navigate(ROUTES.authSplash);
          }}
          variant="contained"
          color="primary"
          size="large"
        >
          {t('buttons.withdrawalEnd')}
        </Button>
      </Box>
      <Box textAlign="center">
        <Button variant="outlined" color="primary" size="medium" startIcon={<BsPrinter />} onClick={handlePrint}>
          {t('buttons.printConfirmation')}
        </Button>
      </Box>
    </SectionBox>
  );
}

export default SuccessStep;
