import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormRadioGroup } from '@omnigenbiodata/ui';

function QuestionRow({ question, answers, value, onChange, questionIndex, error, touched }: any) {
  return (
    <Box mb={5}>
      <Typography variant="h6" component="h3">
        <strong>Q</strong>: {question}
      </Typography>

      <FormRadioGroup
        showOr={false}
        name={`q${questionIndex + 1}`}
        error={error}
        touched={touched}
        value={value}
        options={answers.map((opt: any, index: number) => {
          return { label: opt.answer, value: index };
        })}
        onChange={onChange}
      />
    </Box>
  );
}

export default QuestionRow;
