import base64 from 'base-64';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DMUKLogo, Loader, useAuth, SplashBox, Message } from '@omnigenbiodata/ui';
import AuthLayout from '../../../../layouts/AuthLayout';
import AuthRegisterForm from '../../../../components/forms/AuthRegisterForm';
import ROUTES from '../../../../core/constants/routes.constants';
import { useState } from 'react';

function RegisterScene() {
  const { t } = useTranslation('portal');
  const [submitCount, setSubmitCount] = useState(0);
  const { submitCustomChallenge2, isBusy, hasError, user } = useAuth();

  const handleSubmit = (values: any) => {
    setSubmitCount(submitCount + 1);
    submitCustomChallenge2(
      base64.encode(
        JSON.stringify({
          dob: values.dob,
          first_name: values.firstName,
          last_name: values.surname,
        }),
      ),
    );
  };

  if (hasError || !user?.challengeParam?.email) {
    return <Navigate to={ROUTES.auth} />;
  }

  return (
    <AuthLayout>
      <SplashBox>
        <Box mb={2} mt={5} textAlign="center">
          <Link to={ROUTES.root}>
            <DMUKLogo size="xs" />
          </Link>
        </Box>

        <Box mb={4}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t('register.title')}
          </Typography>
          <Typography paragraph variant="body1" align="center">
            {t('register.text')}
          </Typography>
        </Box>

        {!isBusy && submitCount > 0 && (
          <Message title={t('alerts.MfaFailed.title') as string} severity="error">
            {t('alerts.MfaFailed.text')}
          </Message>
        )}

        <AuthRegisterForm onsubmit={handleSubmit} />
      </SplashBox>

      <Loader isVisible={isBusy} label={t('loaders.registering') as string} />
    </AuthLayout>
  );
}

export default RegisterScene;
