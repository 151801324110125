import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ButtonRow, FormCheckBox, useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ParticipantInformation from '../../../../components/common/ParticipantInformation';
import { StepProps } from '../../../../core/types/common.types';

function PisScene({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');

  const validationSchema = yup.object().shape({
    confirmPIS: yup
      .boolean()
      .required(t('forms.confirmPIS.msg.required') as string)
      .oneOf([true], t('forms.confirmPIS.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { confirmPIS: false, ...values },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'enrolment-pis',
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <ParticipantInformation />
      <Box mb={4}>
        <Grid container>
          <Grid item xs={1} sm={2} md={0}></Grid>
          <Grid item xs={10} sm={8} md={12}>
            <FormCheckBox
              error={formik.errors.confirmPIS}
              name="confirmPIS"
              label={t('forms.confirmPIS.label')}
              onChange={formik.handleChange}
              touched={formik.touched.confirmPIS}
              value={formik.values.confirmPIS}
            />
          </Grid>
        </Grid>
      </Box>
      <ButtonRow buttonSize="medium" onBack={onStepBack} forwardColor="primary" showBack={currentStep > 0} />
    </form>
  );
}

export default PisScene;
