import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { GetConsentPdfLinkResponse, GraphQlError } from '../../core/api/portal.types';

export const getParticipantConsentFormThunk = createAsyncThunk<
  // Return type of the payload creator
  string,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('consentform/get', async (values, thunkApi) => {
  const response: GetConsentPdfLinkResponse = await portalApi.getConsentPdfLink();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getConsentPdfLink as string;
});
