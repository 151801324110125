import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { VimeoPlayer } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import PortalLayout from '../../../../layouts/PortalLayout';

function VideoScene() {
  const { t } = useTranslation('portal');
  return (
    <PortalLayout>
      <Paper>
        <Box pl={2} pr={2}>
          <VimeoPlayer videoUrl={t('enrol.vid.videoUrl')} title={t('enrol.vid.videoTitle')} />
        </Box>
      </Paper>
    </PortalLayout>
  );
}

export default VideoScene;
