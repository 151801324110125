import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../core/constants/routes.constants';
import { useAppSelector } from '../../store';
import { hasSuccessSelector } from '../../store/participant';

function PortalScene() {
  const hasProfile = useAppSelector(hasSuccessSelector);

  if (!hasProfile) {
    return <Navigate to={ROUTES.root} />;
  }

  return <Outlet />;
}

export default PortalScene;
