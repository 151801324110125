import Box from '@mui/material/Box';
import { ButtonRow, FormCheckBox, SectionBox } from '@omnigenbiodata/ui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../core/constants/routes.constants';
import PortalLayout from '../../../../layouts/PortalLayout';
import PrivacyNotice from '../../../../components/common/PrivacyNotice';

function PrivacyScene() {
  const { t } = useTranslation('privacy');
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    privacyAccepted: yup.boolean().oneOf([true], t('forms.privacyAccepted.msg.required', { ns: 'portal' }) as string),
  });

  const formik = useFormik({
    initialValues: { privacyAccepted: false },
    validationSchema,
    onSubmit: () => {
      navigate(ROUTES.policiesComms);
    },
  });

  return (
    <PortalLayout>
      <SectionBox>
        <PrivacyNotice />
        <form onSubmit={formik.handleSubmit}>
          <Box mb={4}>
            <FormCheckBox
              error={formik.errors.privacyAccepted}
              name="privacyAccepted"
              label={t('forms.privacyAccepted.label', { ns: 'portal' })}
              onChange={formik.handleChange}
              touched={formik.touched.privacyAccepted}
              value={formik.values.privacyAccepted}
            />
          </Box>
          <ButtonRow
            buttonSize="medium"
            forwardColor="primary"
            forwardLabel={t('buttons.privacyAccept', { ns: 'portal' }) as string}
            showBack={false}
          />
        </form>
      </SectionBox>
    </PortalLayout>
  );
}

export default PrivacyScene;
