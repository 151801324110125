import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, SectionBox } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { STUDY_EMAIL, STUDY_TEL } from '../../../../../../core/constants/content.constants';

export const WithdrawalIntro = () => {
  const { t } = useTranslation('portal');
  return (
    <SectionBox>
      <Box mb={3}>
        <Typography textAlign="center" variant="h5" component="h1" color="error" paragraph>
          {t('withdrawal.title')}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {t('withdrawal.intro.text')}
        </Typography>
        <Typography textAlign="center" variant="body1" component="span">
          <ReactMarkdown>
            {t('withdrawal.intro.text2', {
              telephone: STUDY_TEL,
              email: STUDY_EMAIL,
            })}
          </ReactMarkdown>
        </Typography>
      </Box>
      <ButtonRow
        showBack={false}
        forwardLabel={`${t('buttons.forward')}`}
        backLabel={`${t('buttons.back')}`}
        buttonSize="medium"
        justifyContent="center"
        forwardColor="error"
      />
    </SectionBox>
  );
};

export default WithdrawalIntro;
