export const ROOT = `/`;
export const SPLASH_BASE = `${ROOT}splash/`;
export const AUTH_BASE = `${ROOT}auth/`;
export const SIGNUP_BASE = `${ROOT}ods/`;
export const PORTAL_BASE = `${ROOT}portal/`;
export const POLICIES_BASE = `${ROOT}policies/`;
export const ENROLMENT_BASE = `${ROOT}enrolment/`;

export const ROUTES = {
  root: ROOT,
  auth: AUTH_BASE,
  authSignOut: `${AUTH_BASE}signedout/`,
  ods: `${SIGNUP_BASE}:odscode`,
  authSplash: `${AUTH_BASE}splash/`,
  authMfa: `${AUTH_BASE}mfa/`,
  authRegister: `${AUTH_BASE}register/`,
  authIdentify: `${AUTH_BASE}identify/`,
  authVerify: `${AUTH_BASE}verify/:verificationCode`,
  policies: POLICIES_BASE,
  policiesPrivacy: `${POLICIES_BASE}privacy`,
  policiesComms: `${POLICIES_BASE}comms`,
  portal: PORTAL_BASE,
  portalEhr: `${PORTAL_BASE}ehr/`,
  portalProfile: `${PORTAL_BASE}profile/`,
  portalPrefs: `${PORTAL_BASE}prefs/`,
  portalWithdraw: `${PORTAL_BASE}withdrawal/`,
  portalPIS: `${PORTAL_BASE}pis/`,
  portalFAQ: `${PORTAL_BASE}faq/`,
  portalVideo: `${PORTAL_BASE}video/`,
  portalSamples: `${PORTAL_BASE}samples/`,
  enrolment: ENROLMENT_BASE,
  enrolmentVideo: `${ENROLMENT_BASE}video/`,
};

export default ROUTES;
