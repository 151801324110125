import { createAsyncThunk } from '@reduxjs/toolkit';
import odsApi from '../../core/api/ods.api';
import { SearchPracticeResponse, GetPracticeResponse } from './types';

export const searchPracticeThunk = createAsyncThunk<
  // Return type of the payload creator
  SearchPracticeResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: any;
  }
>('practices/searchPractice', async (keyword: string, thunkApi) => {
  const response: SearchPracticeResponse = await odsApi.searchPractice(keyword);

  if (response.data) {
    return response.data as SearchPracticeResponse;
  }
  return thunkApi.rejectWithValue(response.errors);
});

export const getPracticeThunk = createAsyncThunk<
  // Return type of the payload creator
  GetPracticeResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: any;
  }
>('practices/getPractice', async (odsCode: string, thunkApi) => {
  const response: GetPracticeResponse = await odsApi.getPracticeByOdsCode(odsCode);

  if (response.data) {
    return response.data as GetPracticeResponse;
  }
  return thunkApi.rejectWithValue(response.errors);
});
