import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';

export const hasErrorSelector = (state: RootState): boolean => (state.enrolment.errors ? true : false);

export const isBusySelector = (state: RootState): boolean => state.enrolment.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): any | null => state.enrolment.response || null;

export const hasSuccessSelector = (state: RootState): any | null => (state.enrolment.response ? true : false);

export const setSelector = (state: RootState): number => state.enrolment.set;

export const subsetSelector = (state: RootState): number => state.enrolment.subset;

export const firstErrorSelector = (state: RootState): string | undefined =>
  state.enrolment.errors && state.enrolment.errors[0] ? state.enrolment.errors[0].errorType : undefined;
