import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ButtonRow, theme } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useAppDispatch } from '../../../../store';
import { resetHasChecked } from '../../../../store/participant';

function SuccessStep() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();

  return (
    <>
      <Grid container>
        <Grid item xs={1} sm={2} md={1}></Grid>
        <Grid item xs={10} sm={8} md={10}>
          <Box mb={4} pl={5} pr={5}>
            <Box textAlign="center">
              <AiOutlineCheckCircle fontSize={90} color={theme.palette.success.main} />
            </Box>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              {t('enrol.success.title')}
            </Typography>
            <Typography variant="body1" component="span" align="center">
              <ReactMarkdown>{t('enrol.success.text')}</ReactMarkdown>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ButtonRow
        buttonSize="medium"
        showBack={false}
        justifyContent="center"
        forwardColor="primary"
        forwardLabel={t('enrol.success.button') as string}
        onForward={() => {
          dispatch(resetHasChecked());
        }}
      />
    </>
  );
}

export default SuccessStep;
