import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import {
  GetParticipantResponse,
  UpdateParticipantResponse,
  GraphQlError,
  UpdateParticipantInput,
  WithdrawParticipantResponse,
  WithdrawParticipantInput,
  UpdateParticipantEmailResponse,
  AcceptLegalNoticesInput,
  AcceptLegalNoticesResponse,
} from '../../core/api/portal.types';

export const getParticipantThunk = createAsyncThunk<
  // Return type of the payload creator
  GetParticipantResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participant/get', async (values, thunkApi) => {
  const response: GetParticipantResponse = await portalApi.getParticipant();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getParticipant as GetParticipantResponse;
});

export const acceptLegalNoticesThunk = createAsyncThunk<
  // Return type of the payload creator
  AcceptLegalNoticesResponse,
  // First argument to the payload creator
  AcceptLegalNoticesInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participant/acceptLegalNotices', async (values, thunkApi) => {
  const response: AcceptLegalNoticesResponse = await portalApi.acceptLegalNotices(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.acceptLegalNotices as AcceptLegalNoticesResponse;
});

export const updateParticipantThunk = createAsyncThunk<
  // Return type of the payload creator
  UpdateParticipantResponse,
  // First argument to the payload creator
  UpdateParticipantInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participant/update', async (values, thunkApi) => {
  const response: UpdateParticipantResponse = await portalApi.updateParticipant(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.updateParticipant as UpdateParticipantResponse;
});

export const withdrawParticipantThunk = createAsyncThunk<
  // Return type of the payload creator
  WithdrawParticipantResponse,
  // First argument to the payload creator
  WithdrawParticipantInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participant/withdraw', async (values, thunkApi) => {
  const response: WithdrawParticipantResponse = await portalApi.withdrawParticipant(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.withdrawParticipant as WithdrawParticipantResponse;
});

export const updateParticipantEmailThunk = createAsyncThunk<
  // Return type of the payload creator
  UpdateParticipantEmailResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participant/updateParticipantEmail', async (code, thunkApi) => {
  const response: UpdateParticipantEmailResponse = await portalApi.updateParticipantEmail(code);
  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.updateParticipantEmail as UpdateParticipantEmailResponse;
});
