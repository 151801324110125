/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';

export interface PolicySectionEntry {
  title: string;
  text: string;
  sections?: PolicySectionEntry[];
}

export interface PolicySectionProps {
  title: string;
  markdown?: string;
  children?: ReactNode[];
}

function PolicySection({ title, markdown, children }: PolicySectionProps) {
  return (
    <Box mb={2}>
      <Typography textAlign="left" variant="body1" fontWeight="bold">
        {title}
      </Typography>
      {markdown && (
        <Typography textAlign="left" variant="body1" component="span">
          <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown>
        </Typography>
      )}
      <Box mb={2} mt={2}>
        {children}
      </Box>
    </Box>
  );
}

export default PolicySection;
