import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { CreateParticipantResponse, CreateParticipantInput, GraphQlError } from '../../core/api/portal.types';

export const createParticipantThunk = createAsyncThunk<
  // Return type of the payload creator
  CreateParticipantResponse,
  // First argument to the payload creator
  CreateParticipantInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('enrolment/enrol', async (values, thunkApi) => {
  const response: CreateParticipantResponse = await portalApi.createParticipant(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.createParticipant as CreateParticipantResponse;
});
