import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { theme, MatomoProvider } from '@omnigenbiodata/ui';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { setupAmplify } from './core/utils/amplify';
import { setupI18next } from './core/utils/i18next';
import reportWebVitals from './reportWebVitals';
import store from './store';
import App from './App';

const config = {
  urlBase: 'https://discovermestudy.matomo.cloud',
  siteId: 5,
  configurations: {
    setSecureCookie: process.env.NODE_ENV !== 'development',
  },
};

setupAmplify();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={setupI18next()}>
        <CssBaseline />
        <MatomoProvider config={config}>
          <App />
        </MatomoProvider>
      </I18nextProvider>
    </ThemeProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
