import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { loginValues } from './types';

export const errorSelector = (state: RootState): any | null => state.user.errors;

export const hasErrorSelector = (state: RootState): boolean => (state.user.errors ? true : false);

export const isBusySelector = (state: RootState): boolean => state.user.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): string => state.user.response || null;

export const valueSelector = (state: RootState): loginValues | null => state.user.value;

export const hasSuccessSelector = (state: RootState): any | null => (state.user.response ? true : false);

export const hasCheckedSelector = (state: RootState): boolean => state.user.hasChecked;
