/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import Fade from '@mui/material/Fade';
import { PageWrapper } from '@omnigenbiodata/ui';
import { useStyles } from './component.styles';
import ENV from '../../core/constants/environment.constants';
import Footer from '../../components/layout/Footer';

interface AuthLayoutProps {
  children: ReactNode;
}

function AuthLayout({ children }: AuthLayoutProps) {
  const classes = useStyles();

  return (
    <PageWrapper isShaded>
      <Fade in timeout={500}>
        <div css={classes.content}>{children}</div>
      </Fade>
      <Footer version={ENV.VERSION} />
    </PageWrapper>
  );
}

export default AuthLayout;
