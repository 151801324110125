export interface KeyValues {
  [key: string]: string | number | boolean | any[];
}

export enum StoreStatus {
  BUSY = 'busy',
  IDLE = 'idle',
  ERROR = 'error',
}

export enum LoginMethod {
  Email = 'Email',
  Mobile = 'Mobile',
}

export enum EhrStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum EnrolmentErrorTypes {
  NHSDUPE = 'NHSNumberAlreadyTakenException',
}

export enum EnrolmentErrorCodes {
  GENERIC = 'EN1001',
  NHSDUPE = 'EN1002',
}

export interface EnrolmentValues {
  firstName?: string;
  surname?: string;
  confirmAge?: boolean;
  dob?: string;
  NHSNumber?: string;
  sexAtBirth?: string;
  genderIdentity?: string;
  genderIdentityOther?: string;
  mobile?: string;
  landline?: string;
  postcode?: string;
  address1?: string;
  address2?: string;
  town?: string;
}

export interface StepProps {
  currentStep: number;
  onStepForward: (values: object) => void;
  onStepTo: (step: number) => void;
  onSubmit: (values: object) => void;
  onStepBack: () => void;
  values: EnrolmentValues;
  isBusy: boolean;
  hasError: boolean;
  errorType?: string;
}
