import { Loader, useAuth } from '@omnigenbiodata/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ResultBox from '../../../../components/common/ResultBox';
import SplashLayout from '../../../../layouts/SplashLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  isBusySelector,
  updateEmailErrorSelector,
  updateEmailResponseSelector,
  updateParticipantEmailThunk,
} from '../../../../store/participant';

function VerifyScene() {
  const { t } = useTranslation('portal');
  const { verificationCode } = useParams();
  const [submits, setSubmits] = useState(0);
  const { signOut } = useAuth();
  const dispatch = useAppDispatch();
  const updateEmailResponse = useAppSelector(updateEmailResponseSelector);
  const updateEmailError = useAppSelector(updateEmailErrorSelector);
  const isBusy = useAppSelector(isBusySelector);

  useEffect(() => {
    if (verificationCode && submits === 0) {
      setSubmits(1);
      dispatch(updateParticipantEmailThunk(verificationCode));
    }
  }, [verificationCode, dispatch, submits, updateEmailResponse, signOut]);
  return (
    <SplashLayout>
      {updateEmailResponse && (
        <ResultBox
          title={t('alerts.VerifySuccess.title') as string}
          text={t('alerts.VerifySuccess.text') as string}
          onClick={signOut}
          button={t('alerts.VerifySuccess.button') as string}
        />
      )}
      {updateEmailError && (
        <ResultBox
          title={t('alerts.VerifyFailed.title') as string}
          text={t('alerts.VerifyFailed.text') as string}
          onClick={signOut}
          severity="error"
          button={t('alerts.VerifyFailed.button') as string}
        />
      )}
      <Loader isVisible={isBusy} label={`${t('loaders.verifying')}`} />
    </SplashLayout>
  );
}

export default VerifyScene;
