import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import {
  GetParticipantPreferencesResponse,
  UpdateParticipantPreferencesResponse,
  GraphQlError,
  UpdateParticipantPreferencesInput,
} from '../../core/api/portal.types';

export const getParticipantPreferencesThunk = createAsyncThunk<
  // Return type of the payload creator
  GetParticipantPreferencesResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('preferences/get', async (values, thunkApi) => {
  const response: GetParticipantPreferencesResponse = await portalApi.getParticipantPreferences();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getParticipantPreferences as GetParticipantPreferencesResponse;
});

export const updateParticipantPreferencesThunk = createAsyncThunk<
  // Return type of the payload creator
  UpdateParticipantPreferencesResponse,
  // First argument to the payload creator
  UpdateParticipantPreferencesInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('preferences/update', async (values, thunkApi) => {
  const response: UpdateParticipantPreferencesResponse = await portalApi.updateParticipantPreferences(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.updateParticipantPreferences as UpdateParticipantPreferencesResponse;
});
