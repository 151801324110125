import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { theme } from '@omnigenbiodata/ui';
import ReactMarkdown from 'react-markdown';
import Grid from '@mui/material/Grid';

export interface InstructionRowProps {
  number: number;
  markdown: string;
}
function InstructionRow({ markdown, number }: InstructionRowProps) {
  return (
    <Grid container spacing={2} alignItems="flex-start" mb={2}>
      <Grid item xs="auto">
        <Typography
          variant="h4"
          textAlign="center"
          component={Box}
          mr={1}
          mt={2}
          width={50}
          color={theme.palette.primary.main}
        >
          {number}
        </Typography>
      </Grid>
      <Grid item xs>
        <Box mr={2}>
          <Typography textAlign="left" variant="body2" component="span">
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default InstructionRow;
