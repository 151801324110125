import Fuse from 'fuse.js';
import { ODSRow } from './ods.types';
import { matchSorter } from 'match-sorter';

const odsApi = {
  searchPractice: async (searchQuery: string): Promise<any> => {
    try {
      const response = await fetch('/data.json').then((response) => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      });

      const options = {
        includeScore: true,
        minMatchCharLength: 2,
        threshold: 0.3,
        distance: 20,
        keys: ['keywords'],
      };

      /* converts fields to keywords so multiword searches have a better chance of matching */
      const allPractices = response.in.map((practice: ODSRow) => ({
        ...practice,
        keywords: [
          practice.Name,
          practice.Postcode,
          practice['Address Line 1'],
          practice['Address Line 2'],
          practice.Town,
        ],
      }));

      /* searches for mathematically probable results */
      const fuse = new Fuse(allPractices, options);
      const data = fuse
        .search(searchQuery)
        .map((row) => {
          return row.item as ODSRow;
        })
        .filter((row: ODSRow) => {
          const roleID = row['Non Primary Role ID(s)'];
          return roleID !== 'RO279';
        });

      /* sorts results like a human */
      const sortedData = matchSorter(data, searchQuery, {
        keys: [
          'Name',
          'Postcode',
          { maxRanking: matchSorter.rankings.CONTAINS, key: 'Address Line 1' },
          { maxRanking: matchSorter.rankings.CONTAINS, key: 'Address Line 2' },
          { maxRanking: matchSorter.rankings.CONTAINS, key: 'Town' },
        ],
        threshold: matchSorter.rankings.NO_MATCH,
      });

      return new Promise((resolve) => {
        resolve({
          data: sortedData,
        });
      });
    } catch (error) {
      return { errors: [error] };
    }
  },
  getPracticeByOdsCode: async (odsCode: string): Promise<any> => {
    try {
      const response = await fetch('/data.json').then((response) => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      });

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: response.in.filter((row: ODSRow) => row.Code === odsCode).pop(),
          });
        }, 3000);
      });
    } catch (error) {
      return { errors: [error] };
    }
  },
};

export default odsApi;
