import { Navigate } from 'react-router-dom';
import ROUTES from '../../../../core/constants/routes.constants';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../store';
import { reset as enrolmentReset } from '../../../../store/enrolment';
import { reset as participantReset } from '../../../../store/participant';
import { reset as preferencesReset } from '../../../../store/preferences';
import { reset as userReset } from '../../../../store/user';
import { reset as consentReset } from '../../../../store/consentForm';

function SignedOut() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(enrolmentReset());
    dispatch(participantReset());
    dispatch(preferencesReset());
    dispatch(userReset());
    dispatch(consentReset());
  }, [dispatch]);

  return <Navigate to={ROUTES.auth} />;
}

export default SignedOut;
