import { createSlice } from '@reduxjs/toolkit';
import { EnrolmentState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { createParticipantThunk } from './thunks';

// Initial State
const initialState: EnrolmentState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  set: Math.round(Math.random()),
  subset: 0,
};

// Slice
export const enrolmentSlice = createSlice({
  name: 'enrolment',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    nextSubset: (state) => {
      state.subset += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createParticipantThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(createParticipantThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    });

    builder.addCase(createParticipantThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });
  },
});

// Action creators
export const { reset, nextSubset } = enrolmentSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default enrolmentSlice.reducer;
