export enum FEATURES {
  CONSENT_FORM = 'consentForm',
  SAMPLES_SUBMIT = 'samplesSubmit',
  SAMPLES_HOLDING = 'samplesHolding',
  EHR = 'ehrData',
  ANCESTRY = 'ancestryData',
  USER_PREFERENCES = 'userPreferences',
  USER_WITHDRAWAL = 'userWithdrawal',
  USER_PROFILE = 'userProfile',
  ENROLMENT_VIDEO = 'enrolmentVideo',
  ENROLMENT_PIS = 'enrolmentPis',
  PORTAL_LEGALS = 'portalLegals',
  PORTAL_PIS = 'portalPIS',
  PORTAL_VIDEO = 'portalVideo',
  PORTAL_FAQ = 'portalFAQ',
  EHR_IMMUNISATIONS = 'ehrImmunisations',
  EHR_CONDITIONS = 'ehrConditions',
}

export const FEATURE_FLAGS = [
  { name: FEATURES.CONSENT_FORM, isActive: true },
  { name: FEATURES.SAMPLES_SUBMIT, isActive: false },
  { name: FEATURES.SAMPLES_HOLDING, isActive: true },
  { name: FEATURES.EHR, isActive: true },
  { name: FEATURES.ANCESTRY, isActive: false },
  { name: FEATURES.USER_PREFERENCES, isActive: true },
  { name: FEATURES.USER_WITHDRAWAL, isActive: true },
  { name: FEATURES.USER_PROFILE, isActive: true },
  { name: FEATURES.ENROLMENT_VIDEO, isActive: false },
  { name: FEATURES.ENROLMENT_PIS, isActive: false },
  { name: FEATURES.ENROLMENT_PIS, isActive: false },
  { name: FEATURES.PORTAL_LEGALS, isActive: true },
  { name: FEATURES.PORTAL_PIS, isActive: true },
  { name: FEATURES.PORTAL_VIDEO, isActive: true },
  { name: FEATURES.PORTAL_FAQ, isActive: true },
  { name: FEATURES.EHR_IMMUNISATIONS, isActive: true },
  { name: FEATURES.EHR_CONDITIONS, isActive: false },
];

export const flagActive = (feature: string) => {
  return FEATURE_FLAGS.find(({ name }) => name === feature)?.isActive;
};
