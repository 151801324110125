import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { PRIVACY_VERSION, TERMS_VERSION } from '../../core/constants/content.constants';
import ROUTES from '../../core/constants/routes.constants';
import { useAppSelector } from '../../store';
import { hasSuccessSelector, responseSelector } from '../../store/participant';

function PoliciesScene() {
  const hasProfile = useAppSelector(hasSuccessSelector);
  const participant = useAppSelector(responseSelector);

  if (
    !hasProfile ||
    (hasProfile && participant.termsVersion === TERMS_VERSION && participant.privacyVersion === PRIVACY_VERSION)
  ) {
    return <Navigate to={ROUTES.root} />;
  }

  return <Outlet />;
}

export default PoliciesScene;
