import { css } from '@emotion/react';
import { theme } from '@omnigenbiodata/ui';

export const useStyles = () => {
  return {
    drawer: css`
      width: 275px;
    `,
    toolbar: css`
      width: auto;
    `,
    icon: css`
      margin-right: 10px;
      font-size: 1.4em;
      color: ${theme.palette.primary.main};
    `,
    iconWrapper: css`
      min-width: auto;
    `,
  };
};
