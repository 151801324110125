import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { theme } from '@omnigenbiodata/ui';
import PisSection from '../PisSection';

export interface HelpDrawerProps {
  id: string;
  isOpen: boolean;
  onToggle: (drawer: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  references: any[];
  pis: any;
}

function HelpDrawer({ id, isOpen, onToggle, references, pis }: HelpDrawerProps) {
  return (
    <Drawer
      data-testid="help-drawer"
      anchor="right"
      PaperProps={{
        sx: { background: theme.palette.info.lighter },
      }}
      open={isOpen}
      onClose={onToggle(id, false)}
    >
      <Box p={5} sx={{ width: '100%', maxWidth: 550 }} role="presentation">
        <Paper>
          <Box p={2}>
            {references.map((s: number) => (
              <PisSection key={s} title={`${pis[s].title}`} text={pis[s].text} />
            ))}
          </Box>
        </Paper>
      </Box>
    </Drawer>
  );
}

export default HelpDrawer;
