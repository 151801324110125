import { createSlice } from '@reduxjs/toolkit';
import { ParticipantPreferencesState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { getParticipantPreferencesThunk, updateParticipantPreferencesThunk } from './thunks';

// Initial State
const initialState: ParticipantPreferencesState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  hasChecked: false,
  hasUpdated: false,
};

// Slice
export const participantPreferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    resetHasChecked: (state) => {
      state.hasChecked = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantPreferencesThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.hasUpdated = false;
    });

    builder.addCase(getParticipantPreferencesThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.hasChecked = true;
      state.errors = null;
    });

    builder.addCase(getParticipantPreferencesThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });

    builder.addCase(updateParticipantPreferencesThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.hasUpdated = false;
    });

    builder.addCase(updateParticipantPreferencesThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.hasChecked = true;
      state.hasUpdated = true;
      state.errors = null;
    });

    builder.addCase(updateParticipantPreferencesThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });
  },
});

// Action creators
export const { reset, resetHasChecked } = participantPreferencesSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default participantPreferencesSlice.reducer;
