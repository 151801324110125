import Box from '@mui/material/Box';
import { FormCheckBox, useTrackedFormik } from '@omnigenbiodata/ui';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StepProps } from '../../../../core/types/common.types';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function Consent8Step({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');

  const validationSchema = yup.object().shape({
    consent8: yup
      .boolean()
      .required(t('forms.consent8.msg.required') as string)
      .oneOf([true], t('forms.consent8.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { consent8: false, ...values },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'consent8',
  );

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.consent8.title')}
      text={t('enrol.consent8.text') as string}
      onBack={onStepBack}
      showBack={currentStep > 0}
    >
      <Box mb={4} px={{ xs: 3, sm: 0 }}>
        <FormCheckBox
          error={formik.errors.consent8}
          name="consent8"
          label={t('forms.consent8.label')}
          onChange={formik.handleChange}
          touched={formik.touched.consent8}
          value={formik.values.consent8}
        />
      </Box>
    </EnrolmentStepContainer>
  );
}

export default Consent8Step;
