import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

export interface WithdrawalConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function WithdrawalConfirmDialog({ isOpen, onClose, onConfirm }: WithdrawalConfirmDialogProps) {
  const { t } = useTranslation('portal');

  return (
    <Dialog
      open={isOpen ? true : false}
      onClose={onClose}
      aria-labelledby="withdrawal-dialog-title"
      aria-describedby="withdrawal-dialog-description"
    >
      <Box p={2}>
        <DialogTitle id="withdrawal-dialog-title">{t('withdrawal.confirm2.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="withdrawal-dialog-description">{t('withdrawal.confirm2.text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="outlined" onClick={onClose}>
            {t('buttons.withdrawalCancel')}
          </Button>
          <Button size="large" variant="contained" color="error" type="button" onClick={onConfirm}>
            {t('buttons.withdrawConfirm')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default WithdrawalConfirmDialog;
