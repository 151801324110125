import { Route, Routes } from 'react-router-dom';
import { AuthRoute } from '@omnigenbiodata/ui';
import ROUTES from '../core/constants/routes.constants';

import AuthScene from './AuthScene';
import PortalScene from './PortalScene';
import EnrolmentScene from './EnrolmentScene';
import LoginScene from './AuthScene/scenes/LoginScene';
import MfaScene from './AuthScene/scenes/MfaScene';
import IdentifyScene from './AuthScene/scenes/IdentifyScene';
import SignedOut from './AuthScene/scenes/SignedOut';
import SplashScene from './AuthScene/scenes/SplashScene';
import HomeScene from './PortalScene/scenes/HomeScene';
import ProfileScene from './PortalScene/scenes/ProfileScene';
import PreferencesScene from './PortalScene/scenes/PreferencesScene';
import WithdrawalScene from './PortalScene/scenes/WithdrawalScene';
import VerifyScene from './AuthScene/scenes/VerifyScene';
import SignupScene from './AuthScene/scenes/SignupScene';
import RegisterScene from './AuthScene/scenes/RegisterScene';
import TermsScene from './PoliciesScene/scenes/TermsScene';
import PoliciesScene from './PoliciesScene';
import PrivacyScene from './PoliciesScene/scenes/PrivacyScene';
import CommsScene from './PoliciesScene/scenes/CommsScene';
import PISScene from './PortalScene/scenes/PISScene';
import FAQScene from './PortalScene/scenes/FAQScene';
import VideoScene from './PortalScene/scenes/VideoScene';
import SamplesScene from './PortalScene/scenes/SamplesScene';
import EhrScene from './PortalScene/scenes/EhrScene';

function Scenes() {
  return (
    <Routes>
      <Route path={ROUTES.auth} element={<AuthScene />}>
        <Route path={ROUTES.auth} element={<LoginScene />} />
        <Route path={ROUTES.authMfa} element={<MfaScene />} />
        <Route path={ROUTES.authIdentify} element={<IdentifyScene />} />
        <Route path={ROUTES.authRegister} element={<RegisterScene />} />
        <Route path={ROUTES.authSignOut} element={<SignedOut />} />
        <Route path={ROUTES.authSplash} element={<SplashScene />} />
        <Route path={ROUTES.authVerify} element={<AuthRoute path={ROUTES.authVerify} element={VerifyScene} />} />
      </Route>
      <Route path={ROUTES.ods} element={<SignupScene />} />
      <Route path={ROUTES.enrolment} element={<AuthRoute path={ROUTES.enrolment} element={EnrolmentScene} />} />
      <Route path={ROUTES.policies} element={<AuthRoute path={ROUTES.policies} element={PoliciesScene} />}>
        <Route path={ROUTES.policies} element={<TermsScene />} />
        <Route path={ROUTES.policiesPrivacy} element={<PrivacyScene />} />
        <Route path={ROUTES.policiesComms} element={<CommsScene />} />
      </Route>
      <Route path={ROUTES.portal} element={<AuthRoute path={ROUTES.portal} element={PortalScene} />}>
        <Route path={ROUTES.portal} element={<HomeScene />} />
        <Route path={ROUTES.portalProfile} element={<ProfileScene />} />
        <Route path={ROUTES.portalPrefs} element={<PreferencesScene />} />
        <Route path={ROUTES.portalWithdraw} element={<WithdrawalScene />} />
        <Route path={ROUTES.portalPIS} element={<PISScene />} />
        <Route path={ROUTES.portalFAQ} element={<FAQScene />} />
        <Route path={ROUTES.portalVideo} element={<VideoScene />} />
        <Route path={ROUTES.portalSamples} element={<SamplesScene />} />
        <Route path={ROUTES.portalEhr} element={<EhrScene />} />
      </Route>
      <Route path="*" element={<AuthRoute path={ROUTES.enrolment} element={SplashScene} />} />
    </Routes>
  );
}

export default Scenes;
