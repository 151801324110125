/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { ButtonRow, FormInput, Message, SectionBox, useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';

export interface EmailFormProps {
  onSubmit?: (values: object) => void;
  initialValues: object;
  isLocked: boolean;
}

function EmailForm({ onSubmit, initialValues, isLocked = false }: EmailFormProps) {
  const { t } = useTranslation('portal');

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(`${t('forms.email.msg.required')}`)
      .required(`${t('forms.email.msg.required')}`),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        email: '',
        ...initialValues,
      },
      validationSchema,
      onSubmit: (values: any) => {
        if (onSubmit) {
          onSubmit(values);
        }
      },
    },
    'update-email',
  );

  if (isLocked) {
    return (
      <SectionBox>
        <Message severity="info" title={`${t('alerts.ProfileEmailLocked.title')}`}>
          {t('alerts.ProfileEmailLocked.text')}
        </Message>
      </SectionBox>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <SectionBox>
        <FormInput
          error={formik.errors.email}
          name="email"
          label={t('forms.email.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.email}
          value={formik.values.email}
        />
      </SectionBox>
      <Box>
        <ButtonRow
          showBack={false}
          forwardLabel={t('buttons.emailSave') || ''}
          forwardColor="primary"
          buttonSize="medium"
        />
      </Box>
    </form>
  );
}

export default EmailForm;
