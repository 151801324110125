import { createSlice } from '@reduxjs/toolkit';
import { UserState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { confirmSignupThunk } from './thunks';

// Initial State
const initialState: UserState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  hasChecked: false,
  value: null,
};

// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(confirmSignupThunk.pending.type, (state, action: any) => {
      state.status = StoreStatus.BUSY;
      state.response = null;
      state.value = action.meta.arg;
    });

    builder.addCase(confirmSignupThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.hasChecked = true;
      state.errors = null;
    });

    builder.addCase(confirmSignupThunk.rejected.type, (state, action: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.response = null;
    });
  },
});

// Action creators
export const { reset } = userSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default userSlice.reducer;
