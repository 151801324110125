import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import enrolmentReducer from './enrolment';
import participantReducer from './participant';
import userReducer from './user';
import practicesReducer from './practices';
import addressLookupReducer from './addressLookup';
import preferencesReducer from './preferences';
import consentformReducer from './consentForm';
import ehrReducer from './ehr';

const store = configureStore({
  reducer: {
    enrolment: enrolmentReducer,
    participant: participantReducer,
    user: userReducer,
    practices: practicesReducer,
    preferences: preferencesReducer,
    consentform: consentformReducer,
    addressLookup: addressLookupReducer,
    ehr: ehrReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
export type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => any = useDispatch; // Export a hook that can be reused

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
