import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { StepProps } from '../../../../core/types/common.types';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function ConsentStep({ currentStep, onStepForward, onStepBack }: StepProps) {
  const { t } = useTranslation('portal');
  const formik = useTrackedFormik(
    {
      initialValues: { consentStart: true },
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'consent',
  );

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.consent.title')}
      onBack={onStepBack}
      showBack={currentStep > 0}
    >
      <Box mb={10} px={1}>
        <Typography variant="body1" component="span" align="left">
          <ReactMarkdown>{t('enrol.consent.text')}</ReactMarkdown>
        </Typography>
      </Box>
    </EnrolmentStepContainer>
  );
}

export default ConsentStep;
