/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { DMUKLogo, PageWrapper, useAuth } from '@omnigenbiodata/ui';
import Box from '@mui/material/Box';
import ENV from '../../core/constants/environment.constants';
import Footer from '../../components/layout/Footer';
import { useStyles } from './component.styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ROUTES from '../../core/constants/routes.constants';
import { useTranslation } from 'react-i18next';

interface EnrolmentLayoutProps {
  children: ReactNode;
}

function EnrolmentLayout({ children }: EnrolmentLayoutProps) {
  const { t } = useTranslation('portal');
  const { signOut } = useAuth();
  const classes = useStyles();

  return (
    <PageWrapper isShaded>
      <Box mb={4} textAlign="center">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Link to={ROUTES.portal}>
              <DMUKLogo size="sm" />
            </Link>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" variant="outlined" onClick={signOut}>
              {t('buttons.exitEnrolment')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Fade in timeout={500}>
        <div css={classes.content}>{children}</div>
      </Fade>
      <Footer version={ENV.VERSION} />
    </PageWrapper>
  );
}

export default EnrolmentLayout;
