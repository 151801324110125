import { createAsyncThunk } from '@reduxjs/toolkit';
import addressApi from '../../core/api/addressLookup.api';
import { GraphQlError } from '../../core/api/portal.types';
import {
  GetAddressesResponse,
  GetAddressesInput,
  GetAddressDetailsInput,
  GetAddressDetailsResponse,
} from '../../core/api/addressLookup.types';

export const getAddressesThunk = createAsyncThunk<
  // Return type of the payload creator
  GetAddressesResponse,
  // First argument to the payload creator
  GetAddressesInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('addressLookup/addresses', async (values, thunkApi) => {
  const response: GetAddressesResponse = await addressApi.getAddresses(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getAddressesQuery as GetAddressesResponse;
});

export const getAddressDetailsThunk = createAsyncThunk<
  // Return type of the payload creator
  GetAddressDetailsResponse,
  // First argument to the payload creator
  GetAddressDetailsInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('addressLookup/addressDetails', async (values, thunkApi) => {
  const response: GetAddressDetailsResponse = await addressApi.getAddressDetails(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getAddressDetailsQuery as GetAddressDetailsResponse;
});
