/** @jsxImportSource @emotion/react */
import differenceInYears from 'date-fns/differenceInYears';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormDob, FormInput, useTrackedFormik } from '@omnigenbiodata/ui';

export interface AuthRegisterFormProps {
  onsubmit?: (values: any) => void;
}

function AuthRegisterForm({ onsubmit }: AuthRegisterFormProps) {
  const { t } = useTranslation('portal');
  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('forms.firstName.msg.required') as string),
    surname: yup.string().required(t('forms.surname.msg.required') as string),
    dob: yup
      .string()
      .test('dob', t('forms.dob.msg.minAge') as string, function (value) {
        return differenceInYears(new Date(), new Date(value || '')) >= 18;
      })
      .required(t('forms.dob.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { firstName: '', surname: '', dob: '' },
      validationSchema,
      onSubmit: (values: any) => {
        if (onsubmit) {
          onsubmit(values);
        }
      },
    },
    'register',
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={5}>
        <FormInput
          error={formik.errors.firstName}
          name="firstName"
          label={t('forms.firstName.label') as string}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.firstName}
          value={formik.values.firstName}
        />
        <FormInput
          error={formik.errors.surname}
          name="surname"
          label={t('forms.surname.label') as string}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.surname}
          value={formik.values.surname}
        />
        <FormDob
          error={formik.errors.dob}
          name="dob"
          label={t('forms.dob.label') as string}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.dob}
          value={formik.values.dob}
          setValue={formik.setFieldValue}
          minAge={18}
        />
      </Box>
      <Box>
        <Button data-testid="register-button" size="large" fullWidth variant="contained" color="primary" type="submit">
          {t('buttons.register')}
        </Button>
      </Box>
    </form>
  );
}

export default AuthRegisterForm;
