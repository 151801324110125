import Typography from '@mui/material/Typography';
import { theme } from '@omnigenbiodata/ui';
import ReactMarkdown from 'react-markdown';
import Grid from '@mui/material/Grid';
import { IconType } from 'react-icons';

export interface TipRowProps {
  icon: IconType;
  markdown: string;
}
function TipRow({ markdown, icon }: TipRowProps) {
  const Icon: any = icon;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs="auto">
        <Icon size={70} color={theme.palette.primary.main} />
      </Grid>
      <Grid item xs>
        <Typography textAlign="left" variant="body1" component="span">
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TipRow;
