/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ScrollablePanel } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { TRANSLATION_VARS } from '../../../core/constants/content.constants';

function FAQs() {
  const { t } = useTranslation('faq');

  return (
    <Paper variant="outlined">
      <Box p={3}>
        <Box mb={3}>
          <Typography textAlign="center" variant="h5" component="h1" paragraph>
            {t('title')}
          </Typography>
        </Box>
        <ScrollablePanel>
          <Typography variant="body1" component="span">
            <ReactMarkdown linkTarget="_blank">{t('intro1', { ...TRANSLATION_VARS })}</ReactMarkdown>
          </Typography>
          <Typography variant="body1" component="span">
            <ReactMarkdown linkTarget="_blank">{t('intro2')}</ReactMarkdown>
          </Typography>
          {Object.values(
            t('sections', {
              returnObjects: true,
              ...TRANSLATION_VARS,
            }),
          ).map((section: any, index: number) => (
            <span key={`section${index}`}>
              <Typography variant="h6" component="h2" align="left" sx={{ paddingTop: 4 }} gutterBottom>
                <strong>
                  {index + 1}. {section.title}
                </strong>
              </Typography>
              {section.subsections ? (
                Object.keys(section.subsections).map((key: string) => (
                  <span key={`section${key}`}>
                    <Typography sx={{ paddingTop: 2 }} variant="subtitle1" component="h4" align="left" gutterBottom>
                      {key}. <span style={{ marginLeft: '30px' }}>{section.subsections[key].title}</span>
                    </Typography>
                    <Typography component="span" variant="body1" align="left">
                      <ReactMarkdown linkTarget="_blank">{section.subsections[key].text}</ReactMarkdown>
                    </Typography>
                  </span>
                ))
              ) : (
                <Typography sx={{ paddingTop: 2 }} component="span" variant="body1" align="left">
                  <ReactMarkdown linkTarget="_blank">{section.text}</ReactMarkdown>
                </Typography>
              )}
            </span>
          ))}
          <Typography sx={{ paddingTop: 4 }} variant="body2" align="left" fontWeight="bold">
            {t('version')} - {t('date')}
          </Typography>
        </ScrollablePanel>
      </Box>
    </Paper>
  );
}

export default FAQs;
