/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';

export interface OpenFileProps {
  isReady: boolean;
  url: string;
  onOpen?: () => void;
}
function OpenFile({ isReady, url, onOpen }: OpenFileProps) {
  useEffect(() => {
    if (url && isReady) {
      window.open(url, '_blank');
      if (onOpen) {
        onOpen();
      }
    }
  }, [url, isReady, onOpen]);

  return null;
}

export default OpenFile;
