import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuth, Loader, DMUKLogo, SplashBox, Message } from '@omnigenbiodata/ui';
import AuthLayout from '../../../../layouts/AuthLayout';
import AuthVerifyForm from '../../../../components/forms/AuthVerifyForm';
import ROUTES from '../../../../core/constants/routes.constants';
import { AUTH_CHALLENGES } from '../../../../core/constants/aws.constants';
import { useAppSelector } from '../../../../store';
import ReactMarkdown from 'react-markdown';
import { errorSelector, responseSelector, valueSelector } from '../../../../store/user';
import { TRANSLATION_VARS } from '../../../../core/constants/content.constants';

function MfaScene() {
  const { t } = useTranslation('portal');
  const [submitCount, setSubmitCount] = useState(0);
  const userError = useAppSelector(errorSelector);
  const userValue = useAppSelector(valueSelector);
  const uuid = useAppSelector(responseSelector);

  const { submitCustomChallenge, isBusy, authChallengeParams, challengeName } = useAuth();

  const handleSubmit = (values: any) => {
    setSubmitCount(submitCount + 1);
    submitCustomChallenge(values.code.toUpperCase());
  };

  if ((!authChallengeParams.email && !userError) || (submitCount === 2 && !isBusy && !uuid)) {
    return <Navigate to={ROUTES.auth} />;
  }

  if (
    challengeName === AUTH_CHALLENGES.CUSTOM_CHALLENGE &&
    authChallengeParams.challengeType === 'confirm_user_details'
  ) {
    return <Navigate to={ROUTES.authIdentify} />;
  }

  if (challengeName === AUTH_CHALLENGES.CUSTOM_CHALLENGE && authChallengeParams.challengeType === 'set_user_details') {
    return <Navigate to={ROUTES.authRegister} />;
  }

  const hideForm = userError && userError[0].errorType === 'UnsupportedODSCodeException';
  return (
    <AuthLayout>
      <SplashBox>
        <Box mb={2} mt={5} textAlign="center">
          <Link to={ROUTES.root}>
            <DMUKLogo size="xs" />
          </Link>
        </Box>

        {!isBusy && userValue?.odsCode && hideForm ? (
          <Box mb={4}>
            <Typography variant="body1" align="center">
              <ReactMarkdown linkTarget="_blank">
                {t('signup.closed', {
                  ...TRANSLATION_VARS,
                })}
              </ReactMarkdown>
            </Typography>
          </Box>
        ) : (
          <Box mb={4}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              {t('mfa.title')}
            </Typography>
            {userValue?.odsCode && (
              <>
                <Typography paragraph variant="body1" align="center">
                  {t('mfa.textRegister')}
                </Typography>
                <Typography paragraph variant="body1" align="center" style={{ wordWrap: 'break-word' }}>
                  <strong>{userValue?.loginParameter || ' '}</strong>
                </Typography>
              </>
            )}
            {!userValue?.odsCode && (
              <>
                <Typography paragraph variant="body1" align="center">
                  {t('mfa.text')}
                </Typography>
                <Typography paragraph variant="body1" align="center" style={{ wordWrap: 'break-word' }}>
                  <strong>{userValue?.loginParameter || ' '}</strong>
                </Typography>
              </>
            )}
          </Box>
        )}

        {!isBusy && submitCount > 0 && (
          <Message title={t('alerts.MfaFailed.title') as string} severity="error">
            {t('alerts.MfaFailed.text')}
          </Message>
        )}
        {!hideForm && <AuthVerifyForm onSubmit={handleSubmit} />}
      </SplashBox>
      <Loader isVisible={isBusy} label={t('loaders.mfaCheck') as string} />
    </AuthLayout>
  );
}

export default MfaScene;
