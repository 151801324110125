import { LogoItem } from '@omnigenbiodata/ui';

export const PARTNER_LOGOS: LogoItem[] = [{ img: '/images/OmnigenFullColour.png', alt: 'Omnigen' }];

export const ENROLMENT_STEPS: string[] = ['Info', 'Consent', 'Details', 'Finish'];

export const ENROLMENT_STEPS_FULL: string[] = [
  'Eligibility',
  'Video',
  'Pis',
  'Questions',
  'Consent',
  'Consent1',
  'Consent2',
  'Consent3',
  'Consent4',
  'Consent5',
  'Consent6',
  'Consent7',
  'Consent8',
  'Consent9',
  'Details1',
  'Details2',
  'Review',
];

export const STUDY_REC = 'North East -Tyne & Wear South Research Ethics Committee';
export const STUDY_REF = '19/NE/0020';
export const STUDY_URL = 'www.discovermestudy.com';
export const STUDY_FULL_URL = 'https://www.discovermestudy.com';
export const STUDY_EMAIL = 'uk@discovermestudy.com';
export const DPO_EMAIL = 'dpo@omnigenbiodata.co.uk';
export const OMNIGEN_PRIVACY_URL = 'https://www.omnigenbiodata.co.uk/?privacy=true';

export const STUDY_TEL = '020 3866 8941';
export const COMPANY_NUMBER = '10565653';
export const STUDY_ADDRESS =
  'FAO Discover Me UK, Omnigen Biodata Ltd, 11 Hope Street Yard, Hope Street, Cambridge CB1 3NA';
export const GENDER_IDENTITY_OTHER = 'Additional category';

export const TERMS_VERSION = '04';
export const PRIVACY_VERSION = '2.0';
export const PIS_VERSION = 'Version 8.0';
export const PIS_DATE = '26 Feb 2024';
export const CONSENT_VERSION = 'v6.0';
export const CONSENT_DATE = '14 Mar 2024';

export const TRANSLATION_VARS = {
  rec: STUDY_REC,
  studyRef: STUDY_REF,
  companyNumber: COMPANY_NUMBER,
  friendlyUrl: STUDY_URL,
  fullUrl: STUDY_FULL_URL,
  studyTel: STUDY_TEL,
  studyEmail: STUDY_EMAIL,
  studyAddress: STUDY_ADDRESS,
  dpoEmail: DPO_EMAIL,
  omnigenPrivacyUrl: OMNIGEN_PRIVACY_URL,
};
