/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { debounce } from '@mui/material';
import { isValid } from 'postcode';
import {
  ButtonRow,
  FormInput,
  FormSelect,
  SectionBox,
  SelectOption,
  useTrackedFormik,
  FormDropdownInput,
} from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import { GENDER_IDENTITY_OTHER } from '../../../../../../core/constants/content.constants';
import { Participant } from '../../../../../../core/api/portal.types';
import {
  addressesSelector,
  addressDetailsSelector,
  getAddressesThunk,
  getAddressDetailsThunk,
  isBusySelector,
  clearAddresses,
} from '../../../../../../store/addressLookup';
import { Address } from '../../../../../../core/api/addressLookup.types';

export interface ProfileFormProps {
  onSubmit?: (values: object) => void;
  initialValues: Participant;
}

function ProfileForm({ onSubmit, initialValues }: ProfileFormProps) {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const addresses = useAppSelector(addressesSelector);
  const addressDetails = useAppSelector(addressDetailsSelector);
  const isBusy = useAppSelector(isBusySelector);

  const validationSchema = yup.object().shape({
    genderIdentityOther: yup.string().when('genderIdentity', (genderIdentity) => {
      if (genderIdentity === GENDER_IDENTITY_OTHER) {
        return yup.string().required(t('forms.genderIdentityOther.msg.required') as string);
      }
      return yup.string();
    }),
    mobile: yup
      .string()
      .test('is-mobile', t('forms.mobile.msg.required') as string, (value) => {
        if (!value) {
          return true;
        }
        const pn = parsePhoneNumber(value, { regionCode: 'GB' });
        return pn.valid && pn.typeIsMobile;
      })
      .required(t('forms.mobile.msg.required') as string),
    landline: yup.string().test('is-landline', t('forms.landline.msg.invalid') as string, (value) => {
      if (!value) {
        return true;
      }
      const pn = parsePhoneNumber(value, { regionCode: 'GB' });
      return pn.valid && pn.typeIsFixedLine;
    }),
    postcode: yup
      .string()
      .min(5)
      .test('is-uk-postcode', t('forms.postcode.msg.required') as string, (value) => {
        if (!value) {
          return true;
        }
        return isValid(value);
      })
      .required(t('forms.postcode.msg.required') as string),
    address1: yup
      .string()
      .min(2)
      .required(t('forms.address1.msg.required') as string),
    address2: yup.string(),
    town: yup
      .string()
      .min(2)
      .required(t('forms.town.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      enableReinitialize: true,
      initialValues: {
        genderIdentity: initialValues.genderIdentity || '',
        genderIdentityOther: initialValues?.genderIdentityOther || '',
        address1: initialValues.address1 || '',
        address2: initialValues.address2 || '',
        town: initialValues.town || '',
        postcode: initialValues.postcode || '',
        mobile: initialValues.mobile || '',
        landline: initialValues.landline || '',
      },
      validationSchema,
      onSubmit: (values: any) => {
        if (onSubmit) {
          onSubmit({
            ...values,
            genderIdentityOther: values.genderIdentity === GENDER_IDENTITY_OTHER ? values.genderIdentityOther : '',
          });
        }
      },
    },
    'update-profile',
  );

  useEffect(() => {
    if (addressDetails) {
      formik.setFieldValue('address1', addressDetails.address1);
      formik.setFieldValue('postcode', addressDetails.postcode);
      formik.setFieldValue('town', addressDetails.town);
      if (addressDetails.address2) {
        formik.setFieldValue('address2', addressDetails.address2);
      } else formik.setFieldValue('address2', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDetails]);

  const searchAddress = useMemo(
    () =>
      debounce((postcode: string, container?: string) => {
        dispatch(getAddressesThunk({ postcode, container }));
      }, 500),
    [dispatch],
  );

  const handleSelect = (option: Address) => {
    if (option.type === 'Container') {
      dispatch(getAddressesThunk({ postcode: option.text, container: option.id }));
    } else dispatch(getAddressDetailsThunk({ id: option.id }));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <SectionBox>
        <FormSelect
          placeholder=""
          error={formik.errors.genderIdentity as string}
          name="genderIdentity"
          label={t('forms.genderIdentity.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.genderIdentity as boolean}
          value={formik.values.genderIdentity}
          options={
            t('forms.genderIdentity.options', {
              returnObjects: true,
            }) as [SelectOption]
          }
        />
        {formik.values.genderIdentity === GENDER_IDENTITY_OTHER && (
          <FormInput
            error={formik.errors.genderIdentityOther as string}
            name="genderIdentityOther"
            label={t('forms.genderIdentityOther.label')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.genderIdentityOther as boolean}
            value={formik.values.genderIdentityOther}
          />
        )}
        <FormDropdownInput
          error={formik.errors.postcode as string}
          name="postcode"
          label={t('forms.postcode.label')}
          onChange={(e: any) => {
            formik.handleChange(e);
            if (e.currentTarget.value.length > 4) {
              searchAddress(e.currentTarget.value);
            } else {
              dispatch(clearAddresses());
            }
          }}
          onBlur={formik.handleBlur}
          onSelect={(option: SelectOption) => {
            handleSelect({
              id: option.value,
              text: option.label,
              description: option.description,
              type: option.type,
            } as Address);
          }}
          touched={formik.touched.postcode as boolean}
          value={formik.values.postcode}
          options={
            addresses
              ? addresses.map((address: Address) => ({
                  value: address.id,
                  label: address.text,
                  description: address.description,
                  type: address.type,
                }))
              : []
          }
          isBusy={isBusy}
          showInline
        />
        <FormInput
          error={formik.errors.address1 as string}
          name="address1"
          label={t('forms.address1.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.address1 as boolean}
          value={formik.values.address1}
        />
        <FormInput
          error={formik.errors.address2 as string}
          name="address2"
          label={t('forms.address2.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.address2 as boolean}
          value={formik.values.address2}
        />
        <FormInput
          error={formik.errors.town as string}
          name="town"
          label={t('forms.town.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.town as boolean}
          value={formik.values.town}
        />
        <FormInput
          error={formik.errors.mobile as string}
          name="mobile"
          label={t('forms.mobile.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.mobile as boolean}
          value={formik.values.mobile}
        />
        <FormInput
          error={formik.errors.landline as string}
          name="landline"
          label={t('forms.landline.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.landline as boolean}
          value={formik.values.landline}
        />
      </SectionBox>
      <Box>
        <ButtonRow
          showBack={false}
          forwardLabel={t('buttons.profileSave') || ''}
          forwardColor="primary"
          buttonSize="medium"
        />
      </Box>
    </form>
  );
}

export default ProfileForm;
