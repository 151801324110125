/** @jsxImportSource @emotion/react */
import * as yup from 'yup';
import { ChangeEvent } from 'react';
import { RiMailLine } from 'react-icons/ri';
import Box from '@mui/material/Box';
import { FormInput, useTrackedFormik } from '@omnigenbiodata/ui';
import Button from '@mui/material/Button';

export interface AuthLoginFormProps {
  buttonLabel: string;
  onsubmit?: (values: any) => void;
  helperText?: string;
}

function AuthLoginForm({ buttonLabel, onsubmit, helperText }: AuthLoginFormProps) {
  const validationSchema = yup.object().shape({
    email: yup.string().email('A valid e-mail address is required').required('A valid e-mail address is required'),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { email: '' },
      validationSchema,
      onSubmit: (values: any) => {
        if (onsubmit) {
          onsubmit(values);
        }
      },
    },
    'login',
  );

  const handleChange = (e: ChangeEvent<any>) => {
    e.target.value = e.target.value.trim();
    formik.handleChange(e);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={5}>
        <FormInput
          error={formik.errors.email as string}
          name="email"
          label={'E-mail address'}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.email as boolean}
          value={formik.values.email}
          startAdornment={<RiMailLine fontSize="large" />}
          helperText={helperText as string}
        />
      </Box>

      <Box>
        <Button size="large" data-testid="login-button" fullWidth variant="contained" color="primary" type="submit">
          {buttonLabel}
        </Button>
      </Box>
    </form>
  );
}

export default AuthLoginForm;
