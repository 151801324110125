import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import {
  GetEhrStatusResponse,
  GetImmunisationsResponse,
  GetConditionsResponse,
  GraphQlError,
} from '../../core/api/portal.types';

export const getEhrStatusThunk = createAsyncThunk<
  // Return type of the payload creator
  GetEhrStatusResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('ehr/getEhrStatus', async (values, thunkApi) => {
  const response: GetEhrStatusResponse = await portalApi.getEhrStatus();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getEhrStatus as GetEhrStatusResponse;
});

export const getImmunisationsThunk = createAsyncThunk<
  // Return type of the payload creator
  GetImmunisationsResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('ehr/getImmunisationsThunk', async (values, thunkApi) => {
  const response: GetImmunisationsResponse = await portalApi.getImmunisations();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getImmunisations as GetImmunisationsResponse;
});

export const getConditionsThunk = createAsyncThunk<
  // Return type of the payload creator
  GetConditionsResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('ehr/getConditionsThunk', async (values, thunkApi) => {
  const response: GetConditionsResponse = await portalApi.getConditions();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getConditions as GetConditionsResponse;
});
