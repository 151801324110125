import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Loader, ScrollToTop, Message, useAuth } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import PortalLayout from '../../../../layouts/PortalLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  isBusySelector,
  hasUpdatedSelector,
  hasErrorSelector,
  updateParticipantThunk,
  responseSelector,
} from '../../../../store/participant';
import EmailForm from './components/EmailForm';
import ProfileForm from './components/ProfileForm';

function ProfileScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const isParticipantBusy = useAppSelector(isBusySelector);
  const hasUpdated = useAppSelector(hasUpdatedSelector);
  const hasError = useAppSelector(hasErrorSelector);
  const profile = useAppSelector(responseSelector);
  const { updateUserAttribute, isBusy, codeDeliveryDetails, user } = useAuth();

  const handleSubmit = (values: any) => {
    dispatch(updateParticipantThunk(values));
  };

  const handleEmailSubmit = (values: any) => {
    updateUserAttribute(values);
  };

  return (
    <PortalLayout>
      <Box mb={3}>
        <Typography textAlign="center" variant="h5" component="h1" paragraph>
          {t('profile.title')}
        </Typography>
        <Typography textAlign="center" variant="body1">
          {t('profile.text')}
        </Typography>
      </Box>
      {hasUpdated && (
        <>
          <ScrollToTop identifier={hasUpdated.toString()} />
          <Message title={`${t('alerts.ProfileUpdateSuccess.title')}`}>{t('alerts.ProfileUpdateSuccess.text')}</Message>
        </>
      )}
      {hasError && (
        <>
          <ScrollToTop identifier={hasError.toString()} />
          <Message severity="error" title={`${t('alerts.ProfileUpdateFailed.title')}`}>
            {t('alerts.ProfileUpdateFailed.text')}
          </Message>
        </>
      )}
      <Box mb={3}>
        <ProfileForm onSubmit={handleSubmit} initialValues={profile} />
      </Box>
      {user && (
        <EmailForm
          onSubmit={handleEmailSubmit}
          initialValues={{ email: (user as any).attributes.email }}
          isLocked={!!codeDeliveryDetails}
        />
      )}

      <Loader isVisible={isParticipantBusy || isBusy} label={`${t('loaders.updating')}`} />
    </PortalLayout>
  );
}

export default ProfileScene;
