import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuth, Loader, DMUKLogo, SplashBox, Message } from '@omnigenbiodata/ui';

import AuthLoginForm from '../../../../components/forms/AuthLoginForm';
import { AUTH_CHALLENGES } from '../../../../core/constants/aws.constants';
import ROUTES from '../../../../core/constants/routes.constants';
import { LoginMethod } from '../../../../core/types/common.types';
import AuthLayout from '../../../../layouts/AuthLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  confirmSignupThunk,
  responseSelector,
  isBusySelector,
  hasErrorSelector,
  reset as resetUserLookup,
} from '../../../../store/user';
import HelpBlock from '../../../../components/common/HelpBlock';
import { TRANSLATION_VARS } from '../../../../core/constants/content.constants';

function LoginScene() {
  const { t } = useTranslation('portal');
  const [submitCount, setSubmitCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const hasUserError = useAppSelector(hasErrorSelector);
  const isUserBusy = useAppSelector(isBusySelector);
  const uuid = useAppSelector(responseSelector);
  const { signIn, challengeName, authChallengeParams, isBusy, hasError, user, resetUser } = useAuth();

  useEffect(() => {
    if (submitCount === 0 && uuid) {
      resetUser();
      dispatch(resetUserLookup());
    }
  }, [dispatch, resetUser, uuid, submitCount]);

  useEffect(() => {
    if (uuid && !isBusy && isSubmitting) {
      signIn(uuid, undefined, {
        loginMethod: LoginMethod.Email,
      });
      setIsSubmitting(false);
    }
  }, [hasError, signIn, user, uuid, dispatch, isBusy, isSubmitting]);

  const handleSubmit = (values: any) => {
    setIsSubmitting(true);
    setSubmitCount(submitCount + 1);
    dispatch(
      confirmSignupThunk({
        loginMethod: LoginMethod.Email.toUpperCase(),
        loginParameter: values.email,
      }),
    );
  };

  if (
    ((challengeName === AUTH_CHALLENGES.CUSTOM_CHALLENGE && authChallengeParams.email) || hasUserError) &&
    submitCount > 0
  ) {
    return <Navigate to={ROUTES.authMfa} />;
  }

  return (
    <AuthLayout>
      <SplashBox>
        <Box mb={2} mt={5} textAlign="center">
          <Link to={ROUTES.root}>
            <DMUKLogo size="xs" />
          </Link>
        </Box>
        <Box mb={4}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t('signin.title')}
          </Typography>
          <Typography paragraph variant="body1" align="center">
            {t('signin.text')}
          </Typography>
        </Box>

        {!isBusy && !isUserBusy && hasError && (
          <Message title={t('alerts.LoginFailed.title') as string} severity="error">
            {t('alerts.LoginFailed.text')}
          </Message>
        )}

        <Box mb={1}>
          <AuthLoginForm onsubmit={handleSubmit} buttonLabel={t('buttons.signIn') as string} />
        </Box>
        <HelpBlock title={t('signin.help.title')} markdown={t('signin.help.text', TRANSLATION_VARS) as string} />
      </SplashBox>
      <Loader isVisible={isBusy || isUserBusy} label={t('loaders.signIn') as string} />
    </AuthLayout>
  );
}

export default LoginScene;
