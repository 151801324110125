import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';

export interface PisSectionProps {
  title: string;
  text: string;
}

function PisSection({ text, title }: PisSectionProps) {
  return (
    <>
      <Typography variant="h6" component="h6">
        {title}
      </Typography>

      <Typography variant="body1" component="span">
        <ReactMarkdown>{text}</ReactMarkdown>
      </Typography>
    </>
  );
}

export default PisSection;
