import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, SectionBox, FormTextarea } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { MultiStepProps } from '../../../../../../components/library/MultiStepper';

export const WithdrawalReason = ({ formik, stepBack }: MultiStepProps) => {
  const { t } = useTranslation('portal');
  return (
    <SectionBox>
      <Box mb={3}>
        <Typography textAlign="center" variant="h5" component="h1" color="error" gutterBottom>
          {t('withdrawal.title')}
        </Typography>
        <Typography textAlign="center" variant="h6" color="error" paragraph>
          {t('withdrawal.reason.title')}
        </Typography>
        <Typography textAlign="center" variant="body1" component="span">
          <ReactMarkdown>{t('withdrawal.reason.text')}</ReactMarkdown>
        </Typography>
      </Box>
      <FormTextarea
        error={formik.errors.withdrawalReason}
        name={'withdrawalReason'}
        label={`${t('forms.withdrawalReason.label')}`}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched.withdrawalReason}
        value={formik.values.withdrawalReason}
      />
      <ButtonRow
        onBack={stepBack}
        forwardLabel={`${t('buttons.forward')}`}
        backLabel={`${t('buttons.back')}`}
        justifyContent="center"
        buttonSize="medium"
        forwardColor="error"
      />
    </SectionBox>
  );
};

export default WithdrawalReason;
