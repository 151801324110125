import { RootState } from '../index';
import { KeyValues, StoreStatus } from '../../core/types/common.types';
import { ODSRow } from './types';

export const hasErrorSelector = (state: RootState): boolean =>
  (state.practices.errors && state.practices.errors) || state.practices.status === StoreStatus.ERROR ? true : false;

export const isBusySelector = (state: RootState): boolean => state.practices.status === StoreStatus.BUSY;

export const valuesSelector = (state: RootState): KeyValues | null => state.practices.values || null;

export const listSelector = (state: RootState): ODSRow[] | null => state.practices.list || null;

export const resultSelector = (state: RootState): ODSRow | undefined => state.practices.result || undefined;
