import { createSlice } from '@reduxjs/toolkit';
import { ParticipantState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import {
  acceptLegalNoticesThunk,
  getParticipantThunk,
  updateParticipantEmailThunk,
  updateParticipantThunk,
  withdrawParticipantThunk,
} from './thunks';

// Initial State
const initialState: ParticipantState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  hasChecked: false,
  hasUpdated: false,
  hasWithdrawn: false,
  updateEmailResponse: null,
  updateEmailError: null,
};

// Slice
export const participantSlice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    resetHasChecked: (state) => {
      state.hasChecked = false;
    },
    resetHasWithdrawn: (state) => {
      state.hasWithdrawn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.hasUpdated = false;
      state.hasWithdrawn = false;
      state.hasChecked = false;
    });

    builder.addCase(getParticipantThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.hasChecked = true;
      state.errors = null;
    });

    builder.addCase(getParticipantThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });

    builder.addCase(updateParticipantThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.hasUpdated = false;
    });

    builder.addCase(updateParticipantThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.hasChecked = true;
      state.hasUpdated = true;
      state.errors = null;
    });

    builder.addCase(updateParticipantThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
    });

    builder.addCase(withdrawParticipantThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.hasWithdrawn = false;
    });

    builder.addCase(withdrawParticipantThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = { ...state.response, ...action.payload };
      state.hasWithdrawn = true;
      state.errors = null;
    });

    builder.addCase(withdrawParticipantThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });

    builder.addCase(updateParticipantEmailThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(updateParticipantEmailThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.updateEmailResponse = action.payload;
    });

    builder.addCase(updateParticipantEmailThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.updateEmailError = payload;
      state.updateEmailResponse = null;
    });

    builder.addCase(acceptLegalNoticesThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(acceptLegalNoticesThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
    });

    builder.addCase(acceptLegalNoticesThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
    });
  },
});

// Action creators
export const { reset, resetHasChecked, resetHasWithdrawn } = participantSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default participantSlice.reducer;
