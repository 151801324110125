import Box from '@mui/material/Box';
import { Message, SummaryTable, useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import { STUDY_EMAIL } from '../../../../core/constants/content.constants';
import { EnrolmentErrorCodes, EnrolmentErrorTypes, StepProps } from '../../../../core/types/common.types';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function ReviewStep({
  currentStep,
  onStepTo,
  onStepBack,
  onSubmit,
  values = {},
  isBusy,
  hasError,
  errorType,
}: StepProps) {
  const { t } = useTranslation('portal');

  const formik = useTrackedFormik(
    {
      initialValues: {},
      onSubmit: () => {
        onSubmit(values);
      },
    },
    'enrolment-review',
  );

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.review.title')}
      text={t('enrol.review.text') as string}
      onBack={onStepBack}
      showBack={currentStep > 0}
      forwardLabel={t('enrol.review.button') as string}
      isDisabled={isBusy}
    >
      {hasError && (
        <Message severity="error" title={`${t('alerts.EnrolmentFailed.title')}`}>
          {t('alerts.EnrolmentFailed.text', {
            email: STUDY_EMAIL,
            ref: errorType === EnrolmentErrorTypes.NHSDUPE ? EnrolmentErrorCodes.NHSDUPE : EnrolmentErrorCodes.GENERIC,
          })}
        </Message>
      )}
      <Box mb={4}>
        <SummaryTable
          onEdit={(key) => {
            if (
              key === 'dateOfBirth' ||
              key === 'NHSNumber' ||
              key === 'sexAtbirth' ||
              key === 'genderIdentity' ||
              key === 'genderIdentityOther'
            ) {
              onStepTo(14);
            } else if (key === 'firstName' || key === 'surname') {
              onStepTo(13);
            } else {
              onStepTo(15);
            }
          }}
          rows={[
            {
              label: t('enrol.review.labels.firstName') as string,
              key: 'firstName',
              value: values.firstName,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.surname') as string,
              key: 'surname',
              value: values.surname,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.dob') as string,
              key: 'dateOfBirth',
              value: values.dob,
              format: 'friendlyDate',
              editFunc: true,
            },
            {
              label: 'NHS Number',
              key: t('enrol.review.labels.NHSNumber') as string,
              value: values.NHSNumber,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.sexAtBirth') as string,
              key: 'sexAtBirth',
              value: values.sexAtBirth,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.genderIdentity') as string,
              key: 'genderIdentity',
              value: values.genderIdentity,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.genderIdentityOther') as string,
              key: 'genderIdentityOther',
              value: values.genderIdentityOther,
              editFunc: true,
              hideIfEmpty: true,
            },
            {
              label: t('enrol.review.labels.mobile') as string,
              key: 'mobile',
              value: values.mobile,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.landline') as string,
              key: 'landline',
              value: values.landline,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.postcode') as string,
              key: 'postcode',
              value: values.postcode,
              format: 'upperCase',
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.address1') as string,
              key: 'address1',
              value: values.address1,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.address2') as string,
              key: 'address2',
              value: values.address2,
              editFunc: true,
            },
            {
              label: t('enrol.review.labels.town') as string,
              key: 'town',
              value: values.town,
              editFunc: true,
            },
          ]}
        />
      </Box>
    </EnrolmentStepContainer>
  );
}

export default ReviewStep;
