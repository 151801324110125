import { useAuth } from '@omnigenbiodata/ui';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import ROUTES from '../../core/constants/routes.constants';

function AuthScene() {
  const [hasCheckedUser, setHasCheckedUser] = useState(false);
  const { isAuthenticated, checkUser, user } = useAuth();
  const { verificationCode } = useParams();

  useEffect(() => {
    if (!hasCheckedUser && !user) {
      checkUser();
      setHasCheckedUser(true);
    }
  }, [checkUser, hasCheckedUser, user]);
  if (isAuthenticated && !verificationCode) {
    return <Navigate to={ROUTES.portal} />;
  }
  return <Outlet />;
}

export default AuthScene;
