import { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { debounce } from '@mui/material';
import { FormInput, useTrackedFormik, FormDropdownInput, SelectOption } from '@omnigenbiodata/ui';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { isValid } from 'postcode';
import { StepProps } from '../../../../core/types/common.types';
import {
  isBusySelector,
  addressesSelector,
  addressDetailsSelector,
  getAddressesThunk,
  getAddressDetailsThunk,
  clearAddresses,
} from '../../../../store/addressLookup';
import { Address } from '../../../../core/api/addressLookup.types';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function Details2Step({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const [enterManual, setManual] = useState(false);
  const addresses = useAppSelector(addressesSelector);
  const addressDetails = useAppSelector(addressDetailsSelector);
  const isBusy = useAppSelector(isBusySelector);

  const validationSchema = yup.object().shape({
    mobile: yup
      .string()
      .test('is-mobile', t('forms.mobile.msg.required') as string, (value) => {
        if (!value) {
          return true;
        }
        const pn = parsePhoneNumber(value, { regionCode: 'GB' });
        return pn.valid && pn.typeIsMobile;
      })
      .required(t('forms.mobile.msg.required') as string),
    landline: yup.string().test('is-landline', t('forms.landline.msg.invalid') as string, (value) => {
      if (!value) {
        return true;
      }
      const pn = parsePhoneNumber(value, { regionCode: 'GB' });
      return pn.valid && pn.typeIsFixedLine;
    }),
    postcode: yup
      .string()
      .test('is-uk-postcode', t('forms.postcode.msg.required') as string, (value) => {
        if (!value) {
          return true;
        }
        return isValid(value);
      })
      .min(5)
      .required(t('forms.postcode.msg.required') as string),
    address1: yup.string().required(t('forms.address1.msg.required') as string),
    address2: yup.string(),
    town: yup.string().required(t('forms.town.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        mobile: '',
        landline: '',
        postcode: '',
        address1: '',
        address2: '',
        town: '',
        ...values,
      },
      validateOnChange: true,
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'enrolment-details-2',
  );

  useEffect(() => {
    if (addressDetails) {
      formik.setFieldValue('address1', addressDetails.address1);
      formik.setFieldValue('postcode', addressDetails.postcode);
      formik.setFieldValue('town', addressDetails.town);
      if (addressDetails.address2) {
        formik.setFieldValue('address2', addressDetails.address2);
      } else formik.setFieldValue('address2', '');
      formik.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDetails]);

  const searchAddress = useMemo(
    () =>
      debounce((postcode: string, container?: string) => {
        if (!enterManual) {
          dispatch(getAddressesThunk({ postcode, container }));
        }
      }, 500),
    [dispatch, enterManual],
  );

  const handleSelect = (option: Address) => {
    if (option.type === 'Container') {
      dispatch(
        getAddressesThunk({
          postcode: formik.values.postcode,
          container: option.id,
        }),
      );
    } else if (option.type === 'Manual') {
      setManual(true);
    } else dispatch(getAddressDetailsThunk({ id: option.id }));
  };

  const options = [
    ...(addresses ? addresses : []),
    {
      type: 'Manual',
      text: "Can't find your address?",
      description: 'Enter your address manually',
      id: 'manual',
    },
  ];

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.details2.title')}
      text={t('enrol.details2.text') as string}
      onBack={onStepBack}
      showBack={currentStep > 0}
    >
      <Box mb={4}>
        <FormInput
          error={formik.errors.mobile}
          name="mobile"
          label={t('forms.mobile.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.mobile}
          value={formik.values.mobile}
        />
        <FormInput
          error={formik.errors.landline}
          name="landline"
          label={t('forms.landline.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.landline}
          value={formik.values.landline}
        />
        <Divider sx={{ my: 2 }}>{t('enrol.details2.addressLookup.title')}</Divider>

        {!(formik.values.address1 || enterManual) && (
          <Typography sx={{ opacity: 0.6 }} textAlign="center" mt={-1} variant="body2">
            {t('enrol.details2.addressLookup.text')}
          </Typography>
        )}
        <FormDropdownInput
          error={formik.errors.postcode}
          name="postcode"
          label={t('forms.postcode.label')}
          onChange={(e: any) => {
            formik.handleChange(e);
            if (e.currentTarget.value.length > 4) {
              searchAddress(e.currentTarget.value);
            } else {
              dispatch(clearAddresses());
            }
          }}
          onBlur={formik.handleBlur}
          onSelect={(option: SelectOption) => {
            handleSelect({
              id: option.value,
              text: option.label,
              description: option.description,
              type: option.type,
            } as Address);
          }}
          touched={formik.touched.postcode}
          value={formik.values.postcode}
          options={options.map((address: Address) => ({
            value: address.id,
            label: address.text,
            description: address.description,
            type: address.type,
          }))}
          disableDropdown={!addresses || enterManual}
          isBusy={isBusy}
          showInline
        />

        {(formik.values.address1 || enterManual) && (
          <>
            <FormInput
              error={formik.errors.address1}
              name="address1"
              label={t('forms.address1.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.address1}
              value={formik.values.address1}
            />
            <FormInput
              error={formik.errors.address2}
              name="address2"
              label={t('forms.address2.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.address2}
              value={formik.values.address2}
            />
            <FormInput
              error={formik.errors.town}
              name="town"
              label={t('forms.town.label')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.town}
              value={formik.values.town}
            />
          </>
        )}
      </Box>
    </EnrolmentStepContainer>
  );
}

export default Details2Step;
