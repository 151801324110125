import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollToTop } from '@omnigenbiodata/ui';

import { StepProps } from '../../../../core/types/common.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { nextSubset, setSelector, subsetSelector } from '../../../../store/enrolment';
import Incorrect from './components/Incorrect';
import QuestionsForm from './components/QuestionsForm';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';
import LockedOut from './components/LockedOut';

function QuestionsScene({ onStepForward, onStepBack, onStepTo }: StepProps) {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const setIndex = useAppSelector(setSelector);
  const subsetIndex = useAppSelector(subsetSelector);

  const [hasFailed, setHasFailed] = useState(false);

  const setsArray: any[] = t('sets', { ns: 'questions', returnObjects: true }); // question content
  const pis: any[] = t('sections', { ns: 'pis', returnObjects: true }); // information sheet content

  const chosenSet = setsArray[setIndex];
  const chosenSubset = chosenSet.subsets[subsetIndex];

  return (
    <>
      <ScrollToTop identifier={`${subsetIndex.toString()}-${hasFailed}`} />
      {!hasFailed && (
        <Box mb={4}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t('enrol.questions.title')}
          </Typography>
          <Typography variant="body1" component="span" align="center">
            <ReactMarkdown>{t('enrol.questions.text')}</ReactMarkdown>
          </Typography>
        </Box>
      )}

      {hasFailed && subsetIndex < chosenSet.subsets.length - 1 && (
        <Incorrect
          onStepTo={(index: number) => {
            dispatch(nextSubset());
            onStepTo(index);
          }}
        />
      )}

      {hasFailed && subsetIndex === chosenSet.subsets.length - 1 && <LockedOut />}

      {chosenSubset?.questions && (
        <QuestionsForm
          disabled={hasFailed}
          pis={pis}
          onStepBack={onStepBack}
          questions={chosenSubset?.questions || []}
          onSubmit={(success: boolean) => {
            if (success) {
              setHasFailed(false);
              onStepForward({});
            } else {
              setHasFailed(true);
            }
          }}
        />
      )}
    </>
  );
}

export default QuestionsScene;
