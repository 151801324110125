import { css } from '@emotion/react';
import { theme } from '@omnigenbiodata/ui';

export const useStyles = () => {
  return {
    appBar: {
      borderTopWidth: 8,
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.primary.main,
    },
    appBarHamburger: { marginRight: theme.spacing(1) },
    content: css`
      flex-grow: 1;
      align-content: center;
    `,
    logoutBtn: css`
      margin-right: 10px;
    `,
  };
};
