import { API as MOCK_AMPLIFY_API } from '../../__mocks__/aws-amplify';
import { API as AMPLIFY_API } from 'aws-amplify';

import { getAddressesQuery, getAddressDetailsQuery } from '../graphql/queries';
import {
  GetAddressesInput,
  GetAddressesResponse,
  GetAddressDetailsInput,
  GetAddressDetailsResponse,
} from './addressLookup.types';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const addressLookupApi = {
  getAddresses: async (values: GetAddressesInput): Promise<any> => {
    try {
      return (await API.graphql({
        query: getAddressesQuery,
        variables: {
          postcode: values.postcode,
          container: values.container,
        },
      })) as GetAddressesResponse;
    } catch (error: any) {
      return error as GetAddressesResponse;
    }
  },
  getAddressDetails: async (values: GetAddressDetailsInput): Promise<any> => {
    try {
      return (await API.graphql({
        query: getAddressDetailsQuery,
        variables: {
          id: values.id,
        },
      })) as GetAddressDetailsResponse;
    } catch (error: any) {
      return error as GetAddressDetailsResponse;
    }
  },
};

export default addressLookupApi;
