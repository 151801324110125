import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';
import { ButtonRow } from '@omnigenbiodata/ui';

interface EnrolmentStepContainerProps {
  onSubmit: () => void;
  title: string;
  text?: string;
  children: ReactNode;
  onBack: () => void;
  showBack: boolean;
  forwardLabel?: string;
  isDisabled?: boolean;
}

function EnrolmentStepContainer({
  onSubmit,
  title,
  text,
  children,
  onBack,
  showBack,
  forwardLabel,
  isDisabled,
}: EnrolmentStepContainerProps) {
  return (
    <form onSubmit={onSubmit}>
      <Grid container sx={{ px: { xs: 1, sm: 0 } }}>
        <Grid item xs={1} sm={2} md={1}></Grid>
        <Grid item xs={12} sm={8} md={10}>
          <Box mb={4} sx={{ px: { xs: 1, sm: 0 } }}>
            <Typography variant="h4" pl={5} pr={5} component="h1" align="center" gutterBottom>
              {title}
            </Typography>
            {text && (
              <Typography variant="body1" component="span" align="left">
                <ReactMarkdown>{text}</ReactMarkdown>
              </Typography>
            )}
          </Box>
          {children}
        </Grid>
        <ButtonRow
          buttonSize="medium"
          onBack={onBack}
          showBack={showBack}
          forwardColor="primary"
          isDisabled={isDisabled}
          forwardLabel={forwardLabel}
        />
      </Grid>
    </form>
  );
}

export default EnrolmentStepContainer;
