import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { ConfirmSignupResponse, GraphQlError, ConfirmSignupInput } from '../../core/api/portal.types';

export const confirmSignupThunk = createAsyncThunk<
  // Return type of the payload creator
  ConfirmSignupResponse,
  // First argument to the payload creator
  ConfirmSignupInput,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('user/confirmSignup', async ({ loginMethod, loginParameter, odsCode }, thunkApi) => {
  const response: ConfirmSignupResponse = await portalApi.confirmSignup(loginMethod, loginParameter, odsCode);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.confirmSignUp as ConfirmSignupResponse;
});
