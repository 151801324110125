import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { theme } from '@omnigenbiodata/ui';
import ReactMarkdown from 'react-markdown';
import { BsQuestionSquare } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

export interface IncorrectProps {
  onStepTo: (step: number) => void;
}
function Incorrect({ onStepTo }: IncorrectProps) {
  const { t } = useTranslation('portal');

  return (
    <Dialog open>
      <DialogContent>
        <Box pt={5} pb={5}>
          <Grid container>
            <Grid item xs={0} sm={2} md={1}></Grid>
            <Grid item xs={12} sm={8} md={10}>
              <Box mb={4} pl={5} pr={5}>
                <Box textAlign="center">
                  <BsQuestionSquare fontSize={90} color={theme.palette.error.main} />
                </Box>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                  {t('alerts.QuestionsTryAgain.title')}
                </Typography>
                <Typography variant="body1" component="span" align="center">
                  <ReactMarkdown>{t('alerts.QuestionsTryAgain.text')}</ReactMarkdown>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Box mb={2}>
              <Button
                variant="outlined"
                size="large"
                data-testid="viewpis"
                onClick={() => {
                  onStepTo(2);
                }}
              >
                {t('enrol.questions.buttons.pis')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              size="large"
              data-testid="viewvideo"
              onClick={() => {
                onStepTo(1);
              }}
            >
              {t('enrol.questions.buttons.video')}
            </Button>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Incorrect;
