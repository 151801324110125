import Box from '@mui/material/Box';
import { FormInput, FormCheckBox, useAuth, useTrackedFormik } from '@omnigenbiodata/ui';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StepProps } from '../../../../core/types/common.types';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function Consent9Step({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');
  const { user } = useAuth();
  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('forms.firstName.msg.required') as string),
    surname: yup.string().required(t('forms.surname.msg.required') as string),
    consent9: yup
      .boolean()
      .required(t('forms.consent9.msg.required') as string)
      .oneOf([true], t('forms.consent9.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        firstName: ((user as any)?.attributes?.given_name as string) || '',
        surname: ((user as any)?.attributes?.family_name as string) || '',
        consent9: false,
        ...values,
      },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'consent9',
  );

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.consent9.title')}
      text={t('enrol.consent9.text') as string}
      onBack={onStepBack}
      showBack={currentStep > 0}
    >
      <Box mb={4}>
        <FormInput
          error={formik.errors.firstName}
          name="firstName"
          label={t('forms.firstName.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.firstName}
          value={formik.values.firstName}
        />
        <FormInput
          error={formik.errors.surname}
          name="surname"
          label={t('forms.surname.label')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.surname}
          value={formik.values.surname}
        />
        <Box px={{ xs: 3, sm: 0 }}>
          <FormCheckBox
            error={formik.errors.consent9}
            name="consent9"
            label={t('forms.consent9.label')}
            onChange={formik.handleChange}
            touched={formik.touched.consent9}
            value={formik.values.consent9}
          />
        </Box>
      </Box>
    </EnrolmentStepContainer>
  );
}

export default Consent9Step;
