import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormCheckBox, SectionBox } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { MultiStepProps } from '../../../../../../components/library/MultiStepper';

export const WithdrawalConfirm = ({ formik, stepBack }: MultiStepProps) => {
  const { t } = useTranslation('portal');

  const chosenOption = (
    (t('forms.withdrawalLevel.options', {
      returnObjects: true,
    }) as any[]) || []
  )
    .filter((item: any) => item.value === formik.values.withdrawalLevel)
    .pop();

  return (
    <>
      <SectionBox>
        <Box mb={3}>
          <Typography textAlign="center" variant="h5" component="h1" color="error" gutterBottom>
            {t('withdrawal.title')}
          </Typography>
          <Typography textAlign="center" variant="h6" color="error" paragraph>
            {t('withdrawal.confirm.title')}
          </Typography>
          <Typography textAlign="center" variant="body1" component="span">
            <ReactMarkdown>{t('withdrawal.confirm.text')}</ReactMarkdown>
          </Typography>
          <Typography textAlign="center" variant="body1" component="span">
            <ReactMarkdown>{t('withdrawal.confirm.text2')}</ReactMarkdown>
          </Typography>
        </Box>
        <Box>
          <Typography textAlign="center" variant="body1" component="span">
            <strong>
              <ReactMarkdown>{chosenOption.labelShort}</ReactMarkdown>
            </strong>
          </Typography>
        </Box>
        <Box mb={3}>
          <Grid container>
            <Grid item xs={1} md={2}></Grid>
            <Grid item xs={10} md={8}>
              <FormCheckBox
                error={formik.errors.withdrawalConfirm}
                name="withdrawalConfirm"
                label={t('forms.withdrawalConfirm.label')}
                onChange={formik.handleChange}
                touched={formik.touched.withdrawalConfirm}
                value={formik.values.withdrawalConfirm}
              />
            </Grid>
          </Grid>
        </Box>
        <ButtonRow
          onBack={stepBack}
          forwardLabel={`${t('buttons.withdraw')}`}
          backLabel={`${t('buttons.back')}`}
          justifyContent="center"
          buttonSize="medium"
          forwardColor="error"
        />
      </SectionBox>
    </>
  );
};

export default WithdrawalConfirm;
