/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ScrollablePanel } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_VARS } from '../../../core/constants/content.constants';
import PolicySection, { PolicySectionEntry } from '../PolicySection';

function TermsConditions() {
  const { t } = useTranslation('terms');

  const sections: PolicySectionEntry[] = Object.values(
    t('sections', {
      returnObjects: true,
      ...TRANSLATION_VARS,
    }),
  );

  return (
    <Paper variant="outlined">
      <Box p={3}>
        <Typography textAlign="center" variant="h5" component="h1" paragraph>
          {t('title')}
        </Typography>
        <Typography textAlign="center" variant="h6" component="h2" paragraph>
          {t('subtitle')}
        </Typography>
        <Typography textAlign="center" variant="body1" color="red" paragraph fontWeight="bold">
          {t('notice1')}
        </Typography>
        <ScrollablePanel>
          {sections.map((section, sIndex: number) => (
            <PolicySection key={`terms-${sIndex}`} title={section.title} markdown={section.text}>
              {section.sections &&
                Object.values(section.sections).map((subsection: any, ssIndex: number) => (
                  <PolicySection
                    key={`terms-${sIndex}-${ssIndex}`}
                    title={subsection.title}
                    markdown={subsection.text}
                  />
                ))}
            </PolicySection>
          ))}
        </ScrollablePanel>
        <Box mt={2}>
          <Typography textAlign="left" variant="body1">
            {t('version')} - {t('date')}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default TermsConditions;
