/** @jsxImportSource @emotion/react */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ScrollablePanel } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { TRANSLATION_VARS } from '../../../core/constants/content.constants';
import PolicySection from '../PolicySection';

function PrivacyNotice() {
  const { t } = useTranslation('privacy');
  const privacySections = Object.values(
    t('sections', {
      returnObjects: true,
      ...TRANSLATION_VARS,
    }),
  );

  return (
    <Paper variant="outlined">
      <Box p={3}>
        <Typography textAlign="center" variant="h5" component="h1" paragraph>
          {t('title')}
        </Typography>
        <ScrollablePanel>
          <Typography textAlign="left" variant="body1" paragraph component="span">
            <ReactMarkdown linkTarget="_blank">{t('text', TRANSLATION_VARS)}</ReactMarkdown>
          </Typography>
          {privacySections.map((privacySection, psIndex) => (
            <PolicySection key={`privacy-${psIndex}`} title={privacySection.title} markdown={privacySection.text} />
          ))}
        </ScrollablePanel>
        <Box mt={2}>
          <Typography textAlign="left" variant="body1">
            {t('version')} - {t('date')}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default PrivacyNotice;
