import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormCheckBox, useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ReactMarkdown from 'react-markdown';
import { StepProps } from '../../../../core/types/common.types';

function EligibilityScene({ currentStep, onStepForward, onStepBack }: StepProps) {
  const { t } = useTranslation('portal');
  const validationSchema = yup.object().shape({
    confirmAge: yup.boolean().oneOf([true], t('forms.confirmAge.msg.required') as string),
    confirmGPInvite: yup.boolean().oneOf([true], t('forms.confirmGPInvite.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: { confirmAge: false, confirmGPInvite: false },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'enrolment-eligibility',
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={4}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          {t('enrol.elig.title')}
        </Typography>
        <Typography variant="body1" component="span" align="center">
          <ReactMarkdown>{t('enrol.elig.text')}</ReactMarkdown>
        </Typography>
      </Box>
      <Box mb={4}>
        <Grid container>
          <Grid item xs={1} sm={2} md={3}></Grid>
          <Grid item xs={10} sm={8} md={6}>
            <FormCheckBox
              error={formik.errors.confirmAge}
              name="confirmAge"
              label={t('forms.confirmAge.label')}
              onChange={formik.handleChange}
              touched={formik.touched.confirmAge}
              value={formik.values.confirmAge}
            />
            <FormCheckBox
              error={formik.errors.confirmGPInvite}
              name="confirmGPInvite"
              label={t('forms.confirmGPInvite.label')}
              onChange={formik.handleChange}
              touched={formik.touched.confirmGPInvite}
              value={formik.values.confirmGPInvite}
            />
          </Grid>
        </Grid>
      </Box>
      <ButtonRow buttonSize="medium" onBack={onStepBack} showBack={currentStep > 0} forwardColor="primary" />
    </form>
  );
}

export default EligibilityScene;
