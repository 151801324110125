import { createSlice } from '@reduxjs/toolkit';
import { AddressLookupState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { getAddressesThunk, getAddressDetailsThunk } from './thunks';

// Initial State
const initialState: AddressLookupState = {
  errors: null,
  status: StoreStatus.IDLE,
  addresses: null,
  addressDetails: null,
};

// Slice
export const addressLookupSlice = createSlice({
  name: 'addressLookup',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    clearAddresses: (state) => ({
      ...state,
      addresses: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAddressesThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.addresses = null;
    });

    builder.addCase(getAddressesThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.addresses = action.payload;
      state.errors = null;
    });

    builder.addCase(getAddressesThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.addresses = null;
    });
    builder.addCase(getAddressDetailsThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.addresses = null;
    });

    builder.addCase(getAddressDetailsThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.addressDetails = action.payload;
      state.addresses = null;
      state.errors = null;
    });

    builder.addCase(getAddressDetailsThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.addressDetails = null;
      state.addresses = null;
    });
  },
});

// Action creators
export const { clearAddresses, reset } = addressLookupSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default addressLookupSlice.reducer;
