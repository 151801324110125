import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.consentform.errors && state.consentform.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.consentform.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): string => state.consentform.response || null;

export const hasSuccessSelector = (state: RootState): any | null => (state.consentform.response ? true : false);
