import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormCheckBox, FormRadioGroup, SectionBox, useTrackedFormik } from '@omnigenbiodata/ui';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { StepProps } from '../../../../core/types/common.types';
import EnrolmentStepContainer from '../EnrolmentStepContainer';

function Consent4Step({ currentStep, onStepForward, onStepBack, values }: StepProps) {
  const { t } = useTranslation('portal');

  const validationSchema = yup.object().shape({
    ehrOptin: yup.boolean().required(t('forms.ehrOptin.msg.required') as string),
    ancestryOptin: yup.boolean().required(t('forms.ancestryOptin.msg.required') as string),
    consent4: yup
      .boolean()
      .required(t('forms.consent4.msg.required') as string)
      .oneOf([true], t('forms.consent4.msg.required') as string),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        consent4: false,
        ehrOptin: '',
        ancestryOptin: '',
        ...values,
      },
      validationSchema,
      onSubmit: (values: any) => {
        onStepForward(values);
      },
    },
    'consent4',
  );

  return (
    <EnrolmentStepContainer
      onSubmit={formik.handleSubmit}
      title={t('enrol.consent4.title')}
      text={t('enrol.consent4.text') as string}
      onBack={onStepBack}
      showBack={currentStep > 0}
    >
      <Box mb={4} px={{ xs: 3, sm: 0 }}>
        <FormCheckBox
          error={formik.errors.consent4}
          name="consent4"
          label={t('forms.consent4.label')}
          onChange={formik.handleChange}
          touched={formik.touched.consent4}
          value={formik.values.consent4}
        />
      </Box>
      <Box mb={2}>
        <SectionBox>
          <Typography paragraph variant="body1" align="left">
            {t('enrol.consent4.ancestry.choice')}
          </Typography>
          <FormRadioGroup
            hideLabel
            error={formik.errors.ancestryOptin}
            name="ancestryOptin"
            label={t('forms.ancestryOptin.label') as string}
            orText={`${t('enrol.consent4.ancestry.or')}`}
            options={[
              {
                value: true,
                label: t('enrol.consent4.ancestry.option1') as string,
              },
              {
                value: false,
                label: t('enrol.consent4.ancestry.option2') as string,
              },
            ]}
            onChange={formik.handleChange}
            touched={formik.touched.ancestryOptin}
            value={formik.values.ancestryOptin}
          />
        </SectionBox>
      </Box>
      <Box mb={4}>
        <SectionBox>
          <Typography paragraph variant="body1" align="left">
            {t('enrol.consent4.ehr.choice')}
          </Typography>
          <FormRadioGroup
            hideLabel
            error={formik.errors.ehrOptin}
            name="ehrOptin"
            label={t('forms.ehrOptin.label') as string}
            orText={`${t('enrol.consent4.ehr.or')}`}
            options={[
              {
                value: true,
                label: t('enrol.consent4.ehr.option1') as string,
              },
              {
                value: false,
                label: t('enrol.consent4.ehr.option2') as string,
              },
            ]}
            onChange={formik.handleChange}
            touched={formik.touched.ehrOptin}
            value={formik.values.ehrOptin}
          />
        </SectionBox>
      </Box>
    </EnrolmentStepContainer>
  );
}

export default Consent4Step;
