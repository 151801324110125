import { useState } from 'react';
import { Loader, useMatomo } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import MultiStepper from '../../../../components/library/MultiStepper';
import { WithdrawParticipantInput } from '../../../../core/api/portal.types';
import PortalLayout from '../../../../layouts/PortalLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { hasWithdrawnSelector, isBusySelector, withdrawParticipantThunk } from '../../../../store/participant';
import SuccessStep from './components/SuccessStep';
import WithdrawalConfirmDialog from './components/WithdrawalConfirmDialog';
import WithdrawalIntro from './components/WithdrawalIntro';
import WithdrawalLevel from './components/WithdrawalLevel';
import WithdrawalReason from './components/WithdrawalReason';
import WithdrawalConfirm from './components/WithdrawalConfirm';

function WithdrawalScene() {
  const { t } = useTranslation('portal');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { trackEvent } = useMatomo();
  const [open, setOpen] = useState(false);
  const [finalValues, setFinalValues] = useState<WithdrawParticipantInput>();
  const dispatch = useAppDispatch();
  const hasWithdrawn = useAppSelector(hasWithdrawnSelector);
  const isBusy = useAppSelector(isBusySelector);
  const handleSubmit = () => {
    if (finalValues) {
      dispatch(withdrawParticipantThunk(finalValues));
    }
  };

  const handleConfirmOpen = () => {
    setOpen(true);
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  return (
    <PortalLayout>
      {!hasWithdrawn && (
        <MultiStepper
          steps={[
            {
              stageIndex: 0,
              component: WithdrawalIntro,
              stepFn: () => trackEvent({ category: 'withdrawal', action: 'start' }),
            },
            {
              stageIndex: 1,
              component: WithdrawalLevel,
              validationSchema: yup.object().shape({
                withdrawalLevel: yup.string().required(`${t('forms.withdrawalLevel.msg.required')}`),
              }),
              stepFn: ({ withdrawalLevel }) =>
                trackEvent({
                  category: 'withdrawal',
                  action: `level ${withdrawalLevel}`,
                }),
            },
            {
              stageIndex: 2,
              component: WithdrawalReason,
              stepFn: () => trackEvent({ category: 'withdrawal', action: 'reason' }),
            },
            {
              stageIndex: 3,
              component: WithdrawalConfirm,
              submit: true,
              validationSchema: yup.object().shape({
                withdrawalConfirm: yup.boolean().isTrue(`${t('forms.withdrawalConfirm.msg.required')}`),
              }),
              stepFn: () => trackEvent({ category: 'withdrawal', action: 'confirm' }),
            },
          ]}
          initialValues={{
            withdrawalReason: '',
            withdrawalLevel: '',
            withdrawalConfirm: false,
          }}
          onSubmit={(values) => {
            setFinalValues(values);
            handleConfirmOpen();
          }}
        />
      )}
      {hasWithdrawn && <SuccessStep />}
      <Loader isVisible={isBusy} label={`${t('loaders.withdrawing')}`} />
      <WithdrawalConfirmDialog
        isOpen={open && !hasWithdrawn && !isBusy}
        onClose={handleConfirmClose}
        onConfirm={handleSubmit}
      />
    </PortalLayout>
  );
}

export default WithdrawalScene;
